import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { colors } from "@styles/constants";
import { Document } from "../../../../types/Document";
import { AccountDetails, MeterType, } from "@customTypes/index";
import { formatDate } from "@utils/Functions";
import { Property } from "../../AccountForm/PropertyFields";
import { PropertySummary } from "./PropertySummary";
import { Person, Responsible } from "./Responsible";
import { FieldRow, FieldsTable } from "../../../../components/molecules/FieldsTable";
import { readableAccountNextSteps, readableMeterType } from "@src/utils/accounts";

export type EnergyAccountProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly account: AccountDetails;
  readonly documents: ReadonlyArray<Document>;
};
export const EnergyAccount: React.FC<EnergyAccountProps> = ({
  persons,
  properties,
  account: { serviceProviderType, meterType, lastMeterReadingDate, nextSteps, responsibleId, propertyId },
  documents,
}) => {
  return (
    <FieldsTable>
      <FieldRow
        label="Property"
        value={<PropertySummary properties={properties} propertyId={propertyId} />}
      />

      <FieldRow label="Next Step" value={readableAccountNextSteps(nextSteps, serviceProviderType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />

      <FieldRow label="Meter type" value={readableMeterType(meterType)} />

      {(meterType === MeterType.PayAsYouGo || meterType === MeterType.Credit) && (
        <FieldRow
          label="Date of the readings"
          value={lastMeterReadingDate && formatDate(lastMeterReadingDate)}
        />
      )}

      {documents && documents.length > 0 && (
        <FieldRow
          label="Documents"
          value={
            <Stack>
              {documents.map((doc, index) => (
                <Box key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={doc.downloadUrl}
                    style={{ color: colors.primary }}
                  >
                    {doc.filename}
                  </a>
                </Box>
              ))}
            </Stack>
          }
        />
      )}
    </FieldsTable>
  );
};
