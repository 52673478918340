import * as React from "react";
import { createAccount, updateAccount } from "@api/online-api";
import { AccountManagement, Document, UploadRecord } from "@src/types";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, ServiceProviderType, NonExpressFormData } from "@src/types";
import { FormStack } from "@molecules/FormStack";
import { AIGAccountFields } from "./AIGAccountFields";
import { BankingAccountFields } from "./BankingAccountFields";
import { ServiceProvider } from "@customTypes/index";
import { CreditCardsAccountFields } from "./CreditCardsAccountFields";
import { DefaultAccountFields } from "./DefaultAccountFields";
import { EnergyAccountFields } from "./EnergyAccountFields";
import { HastingsDirectAccountFields } from "./HastingsDirectAccountFields";
import { InsuranceAccountFields } from "./InsuranceAccountFields";
import { InvestmentsAccountFields } from "./InvestmentsAccountFields";
import { MobileAccountFields } from "./MobileAccountFields";
import { MortgageAccountFields } from "./MortgageAccountFields";
import { PensionAccountFields } from "./PensionAccountFields";
import { Property } from "./PropertyFields";
import { Person } from "./ResponsibleFields";
import {
  recordFromAccount as serviceProviderRecordFromAccount,
  ServiceProviderFields,
} from "./ServiceProviderFields";
import { SocialMediaOrEmailAccountFields } from "./SocialMediaOrEmailAccountFields";
import { WaterAccountFields } from "./WaterAccountFields";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import AccountManagementInfoModal from "@src/components/molecules/AccountManagmentModal";

// Define the props for the AccountForm component
export type AccountFormProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly serviceProvider?: ServiceProvider;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  readonly onAccountUpdated: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly account?: AccountDetails;
  readonly updateTemplate?: (p: any) => void;
  readonly form?: NonExpressFormData;
  readonly savedRecord?: any;
  readonly saveRecord?: (r: any) => void;
  readonly askForDeceasedDetails?: boolean;
};

// Define the props for the account field components
export type AccountFieldProps = {
  readonly account?: AccountDetails;
  readonly addingRecord?: boolean;
  readonly askForDeceasedDetails?: boolean;
  readonly askForDeceasedPostcode?: boolean;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  readonly initialRecord: any;
  readonly onAccountAdded:
    | ((
        a: ExtendedAccount,
        p?: {
          newServiceProvider?: ServiceProvider;
          newPerson?: Person;
          newProperty?: Property;
        }
      ) => void)
    | ((p: any) => void);
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly saveAccount: ((r: { sector: ServiceProviderType; record: any }) => Promise<any>) | any;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly serviceProvider: ServiceProviderRecord;
  readonly setBusy: (b: boolean) => void;
  readonly updateTemplate?: (p: any) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
};

export const AccountForm: React.FC<AccountFormProps> = ({
  caseId,
  signature,
  serviceProvider: defaultServiceProvider,
  serviceProviders,
  properties,
  persons,
  setBusy,
  onAccountUpdated,
  account,
  uploadedFileInfo,
  createDocumentAndUpload,
  updateTemplate,
  form,
  savedRecord,
  saveRecord,
  askForDeceasedDetails,
}) => {
  // Determine if we're adding a new account or updating an existing one
  const addingRecord = !account;

  // State for the selected service provider
  const [serviceProvider, updateServiceProvider] = React.useState<ServiceProviderRecord>(
    serviceProviderRecordFromAccount(account, defaultServiceProvider)
  );

  // Toggle function for Account Management modal visibility
  const toggleAMModal = React.useCallback(() => {
    updateServiceProvider((prevState) => ({
      ...prevState,
      showAMModal: !prevState.showAMModal,
    }));
  }, []);

  // Function to save the account (create or update)
  const saveAccount = React.useCallback(
    ({ sector, record }) => {
      // Select function either to update or create a new account
      const proc = account && account.id ? updateAccount : createAccount;
      return proc({ caseId, signature, sector, record });
    },
    [account, caseId, signature]
  );

  // Initial record for a new account
  const initialRecord = addingRecord
    ? {
        deceased: {},
        emailAddress: form?.deceased?.email,
        phoneNumber: form?.deceased?.contactNumber,
      }
    : undefined;

  // Props to pass to the account field component
  const accountFieldProps: AccountFieldProps = {
    saveAccount,
    properties,
    persons,
    serviceProvider,
    setBusy,
    onAccountAdded: onAccountUpdated,
    account,
    uploadedFileInfo,
    updateTemplate,
    savedRecord,
    saveRecord,
    askForDeceasedDetails,
    askForDeceasedPostcode: serviceProvider?.customForm === "starling-bank",
    addingRecord,
    createDocumentAndUpload,
    initialRecord,
  };

  // Get the appropriate account fields component based on the service provider
  const AccountFieldComponent = getAccountFieldsComponent(serviceProvider);

  return (
    <FormStack>
      {/* If no account and no default service provider, render the service provider fields */}
      {!account && !defaultServiceProvider && (
        <ServiceProviderFields
          serviceProviders={serviceProviders}
          record={serviceProvider || {}}
          errors={{}}
          update={updateServiceProvider}
        />
      )}

      {/* Render the account fields component */}
      <AccountFieldComponent {...accountFieldProps} />

      {/* Account Management Info Modal */}
      <AccountManagementInfoModal
        isDialogVisible={!!serviceProvider.showAMModal}
        setDialogVisibility={toggleAMModal}
        accountManagement={serviceProvider.accountManagement}
      />
    </FormStack>
  );
};

// Function to get the appropriate account fields component
function getAccountFieldsComponent(sp: ServiceProviderRecord) {
  if (!sp?.serviceProviderId && !sp.addingServiceProvider) return NoAccountFields;

  // First, check if a custom form component exists
  const customFormComponent = accountFieldsByCustomForm(sp);
  if (customFormComponent) return customFormComponent;

  // Fallback to component based on service provider type
  return accountFieldsByServiceProviderType(sp);
}

// Function to get account fields component based on custom form
function accountFieldsByCustomForm(sp: ServiceProviderRecord) {
  switch (sp.customForm) {
    case "aig":
      return AIGAccountFields;
    case "hastings-direct":
      return HastingsDirectAccountFields;
    default:
      return null;
  }
}

// Function to get account fields component based on service provider type
function accountFieldsByServiceProviderType(sp: ServiceProviderRecord) {
  switch (sp.serviceProviderType) {
    case ServiceProviderType.Banking:
    case ServiceProviderType.BuildingSociety:
      return BankingAccountFields;
    case ServiceProviderType.CreditCards:
      return CreditCardsAccountFields;
    case ServiceProviderType.Energy:
      return EnergyAccountFields;
    case ServiceProviderType.Insurance:
      return InsuranceAccountFields;
    case ServiceProviderType.Investments:
      return InvestmentsAccountFields;
    case ServiceProviderType.Mobile:
      return MobileAccountFields;
    case ServiceProviderType.Mortgage:
      return MortgageAccountFields;
    case ServiceProviderType.Pension:
      return PensionAccountFields;
    case ServiceProviderType.SocialMedia:
    case ServiceProviderType.Email:
      return SocialMediaOrEmailAccountFields;
    case ServiceProviderType.Water:
      return WaterAccountFields;
    case undefined:
    case null:
      return NoAccountFields;
    default:
      return DefaultAccountFields;
  }
}

// Component to render when there are no account fields
const NoAccountFields: React.FC<AccountFieldProps> = ({ updateTemplate }) => {
  // Use effect to update the template if applicable
  React.useEffect(() => {
    if (updateTemplate) {
      updateTemplate({});
    }
  }, [updateTemplate]);

  return null;
};
