import * as React from "react";

import { AccountType, ServiceProviderType, YesNo, } from "@customTypes/index";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export const InsuranceAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: ServiceProviderType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Policy number"
        value={account.accountNumber ? account.accountNumber : <NotAvailable />}
      />

      <FieldRow label="Customer reference" value={account.holderNumber || <NotAvailable />} />

      <FieldRow
        label="Account type"
        value={
          account.accountType ? (
            readableAccountType(account.accountType, account.accountTypeOther)
          ) : (
            <NotAvailable />
          )
        }
      />

      <FieldRow
        label="Policy name/Scheme type"
        value={account.accountName ? account.accountName : <NotAvailable />}
      />

      {(account.accountType === AccountType.Vehicle ||
        account.accountType === AccountType.BreakdownCover) && (
          <>
          <FieldRow
            label="Vehicle make"
            value={account.vehicleMake ? account.vehicleMake : <NotAvailable />}
          />
          <FieldRow
            label="Vehicle model"
            value={account.vehicleModel ? account.vehicleModel : <NotAvailable />}
          />
          <FieldRow
            label="Vehicle registration number"
            value={
              account.vehicleRegistrationNumber ? (
                account.vehicleRegistrationNumber
              ) : (
                <NotAvailable />
              )
            }
          />
        </>
      )}

      <FieldRow label="Joint policy?" value={account.jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />

      <FieldRow
        label="Responsible"
        value={
          account.responsible.id === "notifier" ? (
            "Myself"
          ) : (
            <PersonDetails person={account.responsible.details} />
          )
        }
      />
    </>
  );
};
