import * as React from "react";

import { Box, Container, Grid, Stack } from "@mui/material";

import { PageHeaderWithSignout } from "@organisms/PageHeaderWithSignout";
import { FormMenuDesktop } from "@molecules/FormMenuDesktop";
import { FormNextPaneDesktop } from "@molecules/FormNextPaneDesktop";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { Help } from "@src/components/atoms/HelpInfo";
import { FormMenuEntry } from "@customTypes/index";

export type FormTemplateDesktopProps = {
  busy?: boolean;
  currentSection?: string;
  error?: string;
  nextLabel?: string;
  saveLabel?: string | null;
  menuEntries: FormMenuEntry[];
  children: React.ReactNode;
  onNext?: () => void;
  onSave?: () => void;
  onSectionClick: (section: string) => void;
};

export const FormTemplateDesktop: React.FC<FormTemplateDesktopProps> = ({
  menuEntries,
  busy,
  currentSection,
  nextLabel,
  saveLabel,
  children,
  onNext,
  onSave,
  onSectionClick,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack rowGap="80px" sx={{ height: "100vh" }}>
        <PageHeaderWithSignout />

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Container>
                <Stack rowGap={6}>
                  <form
                    autoComplete="off"
                    onSubmit={onNext}
                    className="extra-spaced no-side-padding"
                  >
                    {children}
                  </form>
                  <Box sx={{ paddingBottom: 4 }}>
                    <FormNextPaneDesktop
                      busy={busy}
                      nextLabel={nextLabel}
                      saveLabel={saveLabel}
                      onNext={onNext}
                      onSave={onSave}
                    />
                  </Box>
                </Stack>
              </Container>
            </Grid>

            <Grid item xs={4}>
              <Container>
                <FormMenuDesktop
                  currentKey={currentSection}
                  entries={menuEntries}
                  onSelect={onSectionClick}
                />
              </Container>
            </Grid>
            <Grid item xs={7}>
              <Help />
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </BusyOverlay>
  );
};
