import * as React from "react";
import { useNavigate } from "react-router-dom";

import { colors } from "../../styles/constants";
import { Account, AccountStatuses } from "../../types/Account";
import { Button } from "../../components/atoms/Button";
import { AccountCollapsible } from "./AccountCollapsible";
import { Stack, Typography } from "@mui/material";
import { Header } from "../../components/atoms/Header";
import { Add } from "@mui/icons-material";
import urlPaths from "../../urlPaths";
import { AccountManagement } from "@src/types";
import { SelfManagedAccountInfoDialog } from "@src/components/atoms/infoBox";
import { InfoBoxTrigger } from "@src/components/molecules";

type Sections = {
  blocked: Array<Account>;
  processing: Array<Account>;
  settled: Array<Account>;
  additionalServicesAccount: Array<Account>;
  selfManaged: Array<Account>;
};

export const Accounts: React.FC<{
  readonly caseId: string;
  readonly signature: string | null;
  readonly accounts: Array<Account>;
  readonly expressCaseNotSubmitted: boolean;
  readonly readOnly?: boolean;
  // readonly busy: boolean;
  readonly setBusy: (busy: boolean) => void;
}> = ({ signature, caseId, accounts, expressCaseNotSubmitted }) => {
  const navigate = useNavigate();

  const addAccount = React.useCallback(() => {
    navigate("/status/accounts/new");
  }, [navigate]);

  return (
    <AccountsView
      caseId={caseId}
      signature={signature}
      accounts={accounts}
      expressCaseNotSubmitted={expressCaseNotSubmitted}
      addAccount={addAccount}
    />
  );
};

export type AccountsViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly accounts: ReadonlyArray<Account>;
  readonly expressCaseNotSubmitted: boolean;
  readonly addAccount: () => void;
};

export const AccountsView: React.FC<AccountsViewProps> = ({
  caseId,
  signature,
  accounts,
  addAccount,
  expressCaseNotSubmitted,
}) => {
  const sectionsOfServices = React.useMemo(() => {
    const sections = {
      blocked: [],
      processing: [],
      settled: [],
      additionalServicesAccount: [],
      selfManaged: []
    } as Sections;
    if (!accounts) return sections;
    accounts.forEach((account) => {
      if (account.additionalServicesAccount) {
        sections.additionalServicesAccount.push(account);
      } else if (
        !account.serviceProvider?.accountManagement ||
        account.serviceProvider?.accountManagement === AccountManagement.NotManaged ||
        account.serviceProvider?.accountManagement === AccountManagement.NotifyOnly ||
        account.serviceProvider?.accountManagement === AccountManagement.Unknown
      ) {
        sections.selfManaged.push(account);
      } else if (account.status === AccountStatuses.Processing) {
        sections.processing.push(account);
      } else if (account.status === AccountStatuses.Settled) {
        sections.settled.push(account);
      } else if (
        account.status === AccountStatuses.Blocked ||
        account.requiresNotifierAttention ||
        account.status === AccountStatuses.Cancelled
      ) {
        sections.blocked.push(account);
      }
    });

    return sections;
  }, [accounts]);

  return (
    <>
      <Stack spacing={3} sx={{ paddingTop: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2>Selected Additional Services</h2>
        </Stack>

        <Section title="">
          {sectionsOfServices.additionalServicesAccount.length === 0 ? (
            <NoAccountsText>
              No Additional Services Requested.Select from above and reload
            </NoAccountsText>
          ) : (
            sectionsOfServices.additionalServicesAccount.map((account) => (
              <div key={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="blocked"
                />
              </div>
            ))
          )}
        </Section>
      </Stack>

      <Stack spacing={3} sx={{ paddingTop: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2>Your accounts</h2>

          {expressCaseNotSubmitted ? (
            <Button
              icon={<Add fontSize="small" />}
              href={urlPaths.form()}
              variant="primary"
              size="large"
            >
              Add more accounts
            </Button>
          ) : (
            <Button
              icon={<Add fontSize="small" />}
              onClick={addAccount}
              variant="primary"
              size="large"
            >
              Add account
            </Button>
          )}
        </Stack>

        <Section title={`Requires your attention (${sectionsOfServices.blocked.length})`}>
          {sectionsOfServices.blocked.length === 0 ? (
            <NoAccountsText>No accounts require your attention.</NoAccountsText>
          ) : (
            sectionsOfServices.blocked.map((account) => (
              <div key={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="blocked"
                />
              </div>
            ))
          )}
        </Section>

        <Section title={`In progress (${sectionsOfServices.processing.length})`}>
          {sectionsOfServices.processing.length === 0 ? (
            <NoAccountsText>No accounts in progress.</NoAccountsText>
          ) : (
            sectionsOfServices.processing.map((account) => (
              <div key={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="processing"
                />
              </div>
            ))
          )}
        </Section>

        <Section
          title={
            <>
              Self Managed Accounts
              {"  "}
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <InfoBoxTrigger white width="448px" content={SelfManagedAccountInfoDialog()} />
              </span>
            </>
          }
        >
          {sectionsOfServices.selfManaged.length === 0 ? (
            <NoAccountsText>No accounts in progress.</NoAccountsText>
          ) : (
            sectionsOfServices.selfManaged.map((account) => (
              <div key={account.id}>
                <AccountCollapsible
                  caseId={caseId}
                  signature={signature}
                  account={account}
                  section="processing"
                />
              </div>
            ))
          )}
        </Section>

        <Section title={`Completed (${sectionsOfServices.settled.length})`}>
          {sectionsOfServices.settled.length === 0 ? (
            <NoAccountsText>No accounts completed yet.</NoAccountsText>
          ) : (
            sectionsOfServices.settled.map((account) => (
              <div key={account.id}>
                <AccountCollapsible
                  key={account.id}
                  signature={signature}
                  caseId={caseId}
                  account={account}
                  section="settled"
                />
              </div>
            ))
          )}
        </Section>
      </Stack>
    </>
  );
};

export type SectionProps = {
  readonly title: React.ReactNode;
  readonly children: React.ReactNode;
};

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <Stack rowGap={1}>
      <Header level={2}>{title}</Header>
      <Stack rowGap={2}>{children}</Stack>
    </Stack>
  );
};

type NoAccountsTextProps = {
  readonly children: React.ReactNode;
};

const NoAccountsText: React.FC<NoAccountsTextProps> = ({ children }) => (
  <Typography variant="body1" color={colors.fadedText}>
    {children}
  </Typography>
);
