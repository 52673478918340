import { Stack, Typography } from "@mui/material";
import { InfoBoxTrigger } from "../molecules";

export const phoneNumberInfoBox = () => {
  return (
    <Stack rowGap={2} sx={{ paddingTop: 2 }}>
      <Typography variant="body1">
        <b>Why do I need to provide a phone number?</b>
      </Typography>

      <Typography variant="body1">
        We request service providers to cease all product and marketing calls to this number.
      </Typography>
    </Stack>
  );
};

export const emailAddressInfoBox = () => {
  return (
    <Stack rowGap={2} sx={{ paddingTop: 2 }}>
      <Typography variant="body1">
        <b>Why do I need to provide an email?</b>
      </Typography>

      <Typography variant="body1">
        We also request service providers to cease all product and marketing communications to this
        email address. <i>If there is no email address please put N/A.</i>
      </Typography>
    </Stack>
  );
};

export const SelfManagedAccountInfoDialog = () => {
  return (
    <Stack rowGap={2} sx={{ paddingTop: 2 }}>
      <Typography variant="body1">
        <b>There is no status updates for these accounts </b>
      </Typography>

      <Typography variant="body1">
        Please note, the service providers in this queue currently do not support notifications from
        Settld. We apologise for any inconvenience caused, and are working hard to bring these
        companies on board.
      </Typography>
    </Stack>
  );
};

export function newSPInfoBox() {
  return (
    <>
      <span>
        Who is the service provider?{" "}
        <InfoBoxTrigger
          white
          content={
            <Stack rowGap={4}>
              <Stack rowGap={2}>
                <Typography variant="body1">
                  <b>My service provider isn’t listed. What should I do?</b>
                </Typography>

                <Typography variant="body1">
                  Settld will help you to notify any company, even if they aren’t listed yet. If you
                  If you can’t find the organisation, please:{" "}
                </Typography>

                <ol style={{ marginTop: 0 }}>
                  <li>Enter the name of your service provider in the text box</li>
                  <li>Provide the details for the account</li>
                  <li>We will contact them for you</li>
                </ol>
              </Stack>
            </Stack>
          }
        />
      </span>
    </>
  );
}