import { NotifierDetails, NotifierRoles } from "@src/types";
import { de } from "date-fns/locale";


export function isAskWillAndNok(intestacyFlow: boolean | undefined, notifierRole: NotifierRoles | undefined, willAvailable: boolean | undefined):
  [isAskWill: boolean, isAskNok: boolean] {
  if (!intestacyFlow) return [false, false];

  switch (notifierRole) {
    case NotifierRoles.Administrator:
      return [false, true];
    case NotifierRoles.DelegatedNotifier:
      return [true, isAskNok(willAvailable)];

    default:
      return [false, false]
  }
}

function isAskNok(willAvailable: boolean | undefined): boolean {
  // If willAvailable is undefined or false, we need to ask for NOK
  switch (willAvailable) {
    case undefined:
      return false;

    default:
      return !willAvailable;
  }
}



export function uism(record: NotifierDetails,  willAvailable: boolean):
 { collectNokDetails: boolean, collectExecutorDetails: boolean, willExists: boolean } {
  switch (record.role) {
    case NotifierRoles.SoleExecutor:
    case NotifierRoles.Executor:
    case NotifierRoles.Solicitor:
      // will exists thats why exec/solic is filing it
      return { collectNokDetails: false, collectExecutorDetails: false, willExists: true };

    case NotifierRoles.Administrator:
      // An Administrator (there is no will)
      if (record.nok === undefined || record.nok === true) {
        // notifer is nok do not collect nok info 
        //  notifier has not chosen anything
        return { collectExecutorDetails: false, willExists: false, collectNokDetails: false };
      } else {
        // notifier is not nok collect nok details
        return { collectExecutorDetails: false, willExists: false, collectNokDetails: true };
      }

    case NotifierRoles.DelegatedNotifier:
      if (!!willAvailable)
        // will exists collect executor info and will in documents
        return { collectExecutorDetails: true, willExists: true, collectNokDetails: false };
      else {
        // will does not exists collect nok info and will in documents
        if (record.nok === undefined || record.nok === true) {
          // notifer is nok do not collect nok info 
          //  notifier has not chosen anything
          return { collectExecutorDetails: false, willExists: false, collectNokDetails: false };
        } else {
          // notifier is not nok collect nok details
          return { collectExecutorDetails: false, willExists: false, collectNokDetails: true };
        }
      }
      default: 
      return { collectExecutorDetails: false, willExists: false, collectNokDetails: false };
  }
}

