import * as React from "react";

import { ServiceProviderType, } from "@customTypes/index";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export const PensionAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: ServiceProviderType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Policy or customer reference"
        value={account.holderNumber ? account.holderNumber : <NotAvailable />}
      />

      <FieldRow
        label="Policy type"
        value={
          account.accountType ? (
            readableAccountType(account.accountType, account.accountTypeOther)
          ) : (
            <NotAvailable />
          )
        }
      />

      <FieldRow
        label="Policy name/Scheme type"
        value={account.accountName ? account.accountName : <NotAvailable />}
      />

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />

      <FieldRow
        label="Responsible"
        value={
          account.responsible.id === "notifier" ? (
            "Myself"
          ) : (
            <PersonDetails person={account.responsible.details} />
          )
        }
      />
    </>
  );
};
