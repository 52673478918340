import * as React from "react";

import { prettyTitle } from "@src/types";
import { formatDate } from "@utils/Functions";

export const PersonDetails = ({ person }: any) => {
  return (
    <span>
      {prettyTitle(person.title)} {person.firstName} {person.lastName}
      <br />
      {person.address && person.city && person.postcode && (
        <>
          {person.address} <br /> {person.city} <br /> {person.postcode}
          <br />
        </>
      )}
      {person.phoneNumber}
      <br />
      {person.emailAddress}
      <br />
      Born on {formatDate(person.dateOfBirth)}
      {person.isVulnerable ? (
        <>
          <br />
          Is vulnerable
        </>
      ) : (
        ""
      )}
    </span>
  );
};
