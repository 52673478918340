import { assertUnreachable } from "@utils/Functions";
import { UiInfo } from "./store/NonExpressState";

export type NonExpressFormStep = {
  readonly requirements?: boolean;
  readonly permission?: boolean;
  readonly accounts?: boolean;
  readonly deceased?: boolean;
  readonly notifier?: boolean;
  readonly nok?: boolean;
  readonly executor?: boolean;
  readonly intestacy?: boolean;
  readonly documents?: boolean;
  readonly kyc?: boolean;
  readonly submit?: boolean;
};

export enum Section {
  Accounts = "accounts",
  Deceased = "deceased",
  Notifier = "notifier",
  Nok = "nok",
  Executor = "executor",
  Documents = "documents",
  KYC = "kyc",
  Submit = "submit",
}

export const nextSection = (section: Section, { bypassKYC, collectNokDetails, intestacyFlow, collectExecutorDetails }: any): Section => {
  switch (section) {
    case Section.Accounts:
      return Section.Deceased;
    case Section.Deceased:
      return Section.Notifier;
    case Section.Notifier:
      if (collectNokDetails && intestacyFlow) return Section.Nok;
      if (collectExecutorDetails && intestacyFlow) return Section.Executor;
      return Section.Documents;
    case Section.Nok:
      return Section.Documents;
    case Section.Executor:
      return Section.Documents;
    case Section.Documents:
      return bypassKYC ? Section.Submit : Section.KYC;
    case Section.KYC:
      return Section.Submit;
    case Section.Submit:
      return Section.Accounts;
  }
  assertUnreachable(section);
};

export const sectionForPath = (pathname: string): Section => {
  return (
    {
      "/accounts": Section.Accounts,
      "/deceased": Section.Deceased,
      "/notifier": Section.Notifier,
      "/nok": Section.Nok,
      "/executor": Section.Executor,
      "/documents": Section.Documents,
      "/kyc": Section.KYC,
      "/submit": Section.Submit,
    }[pathname] || Section.Accounts
  );
};

export const pathForSection = (section: Section): string => {
  switch (section) {
    case Section.Accounts:
      return "/accounts";
    case Section.Deceased:
      return "/deceased";
    case Section.Notifier:
      return "/notifier";
    case Section.Nok:
      return "/nok";
    case Section.Executor:
      return "/executor";
    case Section.Documents:
      return "/documents";
    case Section.KYC:
      return "/kyc";
    case Section.Submit:
      return "/submit";
  }
  assertUnreachable(section);
};

export const furthestSubmittedSection = (state: NonExpressFormStep): Section | null => {
  if (state.submit) return Section.Submit;
  if (state.kyc) return Section.KYC;
  if (state.documents) return Section.Documents;
  if (state.nok) return Section.Nok;
  if (state.executor) return Section.Executor;
  if (state.notifier) return Section.Notifier;
  if (state.deceased) return Section.Deceased;
  if (state.accounts) return Section.Accounts;
  return null;
};

export const earliestUnsubmittedSection = (
  state: NonExpressFormStep,
  { bypassKYC, intestacyFlow, collectNokDetails, collectExecutorDetails }: UiInfo
): Section | null => {
  if (!state.accounts) return Section.Accounts;
  if (!state.deceased) return Section.Deceased;
  if (!state.notifier) return Section.Notifier;
  if (!state.executor && intestacyFlow && collectExecutorDetails) return Section.Executor;
  if (!state.nok && intestacyFlow &&  collectNokDetails) return Section.Nok;
  if (!state.documents) return Section.Documents;
  if (!state.kyc && !bypassKYC) return Section.KYC;
  if (!state.submit) return Section.Submit;
  return null;
};
