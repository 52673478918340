import * as React from "react";

import { Alert, Box, Paper, Snackbar, Stack, Typography } from "@mui/material";

import { useSectionViewEventTracker } from "@utils/analytics";
import { AccountDetails, NonExpressFormData, Document, UploadRecord } from "@customTypes/index";
import { ReactStateSetter } from "../../utils/Types";
import { Header, Subheader } from "../../components/atoms/Header";
import { UploadField, UploadsRecord } from "../../components/organisms/UploadField";
import { Section } from "../../Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { Accordion } from "../../components/molecules/Accordion";
import { colors } from "@src/styles/constants";
import { ServiceProvider } from "@customTypes/index";
import { HR } from "../../components/atoms/HR";
import { updateDocuments } from "@api/caseApi";
import { RequiredHeader } from "@molecules/RequiredHeader";
import { isWillRequired } from "@customTypes/Document";
import { NEContext } from "@src/store/NonExpressState";

export type DocumentsPersistedState = {
  readonly record: DocumentsRecord;
  readonly errors: Errors;
  readonly hasChanges: boolean;
  readonly refreshPreviousDocuments: number;
};

type DocumentsRecord = {
  readonly proofOfAddress: UploadsRecord;
  readonly proofOfDeath: UploadsRecord;
  readonly willAndProbate: UploadsRecord;
  readonly grantOfRepresentation: UploadsRecord;
};

type DocumentIds = {
  readonly proofOfAddress: string[];
  readonly proofOfDeath: string[];
  readonly willAndProbate: string[];
  readonly grantOfRepresentation: string[];
};

type Errors = {
  proofOfAddress?: string;
  proofOfDeath?: string;
  willAndProbate?: string;
  grantOfRepresentation?: string;
};

export const documentsPersistedStateFromForm = (
  form: NonExpressFormData
): DocumentsPersistedState => {
  const proofOfAddress = form.documents?.proofOfAddressIds || [];
  const proofOfDeath = form.documents?.proofOfDeathIds || [];
  const willAndProbate = form.documents?.willAndProbateIds || [];
  const grantOfRepresentation = form.documents?.grantOfRepresentationIds || [];

  return {
    record: {
      proofOfAddress: {
        originalIds: proofOfAddress,
        currentIds: proofOfAddress,
        previousDocuments: [],
        files: [],
      },
      proofOfDeath: {
        originalIds: proofOfDeath,
        currentIds: proofOfDeath,
        previousDocuments: [],
        files: [],
      },
      willAndProbate: {
        originalIds: willAndProbate,
        currentIds: willAndProbate,
        previousDocuments: [],
        files: [],
      },
      grantOfRepresentation: {
        originalIds: grantOfRepresentation,
        currentIds: grantOfRepresentation,
        previousDocuments: [],
        files: [],
      },
    },
    hasChanges: false,
    errors: {},
    refreshPreviousDocuments: 0,
  };
};

const updatedFormFromPersistedState = (
  form: NonExpressFormData,
  state: DocumentsPersistedState,
  newIds: DocumentIds
): NonExpressFormData => {
  const documents = {
    proofOfAddressIds: newIds.proofOfAddress,
    proofOfDeathIds: newIds.proofOfDeath,
    willAndProbateIds: newIds.willAndProbate,
    grantOfRepresentationIds: newIds.grantOfRepresentation,
  };

  return {
    ...form,
    documents: documents,
  };
};

const serverRecordFromPersistedState = (state: DocumentsPersistedState, newIds: DocumentIds) => {
  return {
    proofOfAddressIds: newIds.proofOfAddress,
    deathCertificateIds: newIds.proofOfDeath,
    willAndProbateIds: newIds.willAndProbate,
    grantOfRepresentationIds: newIds.grantOfRepresentation,
  };
};

export type DocumentsProps = {
  caseId: string;
  signature: string | null;
  prepareUpdate: Function;
  updateSuccessful: Function;
  updateFailure: (error: Error) => void;
  serviceProvidersMap: Record<string, ServiceProvider>;
  form: NonExpressFormData;
  busy: boolean;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  remoteError: string | undefined;
  uploadFile: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  uploadedFileInfo: (id: string) => Promise<Document>;
  persistedState: DocumentsPersistedState;
  setPersistedState: ReactStateSetter<DocumentsPersistedState>;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
};

export const Documents: React.FC<DocumentsProps> = ({
  caseId,
  signature,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  serviceProvidersMap,
  busy,
  continueWithoutChanges,
  remoteError,
  uploadFile,
  uploadedFileInfo,
  persistedState,
  setPersistedState,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
}) => {
  useSectionViewEventTracker("Documents");
  const { record, errors, hasChanges } = persistedState;
  const { state: { willExists, intestacyFlow } } = React.useContext(NEContext);
  const askForGrantOfRepresentation = React.useMemo(
    () =>
      form.accounts?.some(
        (account: AccountDetails) =>
          account.companyId && serviceProvidersMap[account.companyId]?.grantOfRepresentationNeeded
      ),
    [serviceProvidersMap, form.accounts]
  );

  const askForWillAndProbate = React.useMemo(() => {
    return isWillRequired(form?.accounts, form?.notifier, serviceProvidersMap, intestacyFlow , willExists);
  }, [serviceProvidersMap, form.accounts, form.notifier]);

  const askForProofOfAddress = React.useMemo(
    () =>
      form.accounts?.some(
        (account: AccountDetails) =>
          account.companyId && serviceProvidersMap[account.companyId]?.proofOfAddressNeeded
      ),
    [serviceProvidersMap, form.accounts]
  );

  const updateProofOfAddress = React.useCallback(
    (proc: (a: UploadsRecord) => UploadsRecord, initial?: boolean) => {
      setPersistedState((s) => ({
        ...s,
        hasChanges: !initial,
        record: { ...s.record, proofOfAddress: proc(s.record.proofOfAddress) },
      }));
    },
    [setPersistedState]
  );

  const updateProofOfDeath = React.useCallback(
    (proc: (a: UploadsRecord) => UploadsRecord, initial?: boolean) => {
      setPersistedState((s) => ({
        ...s,
        hasChanges: !initial,
        record: { ...s.record, proofOfDeath: proc(s.record.proofOfDeath) },
      }));
    },
    [setPersistedState]
  );

  const updateWillAndProbate = React.useCallback(
    (proc: (a: UploadsRecord) => UploadsRecord, initial?: boolean) => {
      setPersistedState((s) => ({
        ...s,
        hasChanges: !initial,
        record: { ...s.record, willAndProbate: proc(s.record.willAndProbate) },
      }));
    },
    [setPersistedState]
  );

  const updateGrantOfRepresentation = React.useCallback(
    (proc: (a: UploadsRecord) => UploadsRecord, initial?: boolean) => {
      setPersistedState((s) => ({
        ...s,
        hasChanges: !initial,
        record: { ...s.record, grantOfRepresentation: proc(s.record.grantOfRepresentation) },
      }));
    },
    [setPersistedState]
  );

  const setError = React.useCallback(
    (name: string, message: string) => {
      setPersistedState((s) => ({
        ...s,
        hasChanges: true,
        record: { ...s.record },
        errors: { ...s.errors, [name]: message },
      }));
    },
    [setPersistedState]
  );

  const onContinue = React.useCallback(
    async (justSave?: boolean) => {
      const errors = validateRecord(record, !!askForProofOfAddress, !!askForWillAndProbate);

      if (errors) {
        setPersistedState({
          ...persistedState,
          errors,
        });
        return;
      }

      if (!hasChanges) {
        continueWithoutChanges(Section.Documents, justSave);
        return;
      }

      const proofOfAddressUploads = record.proofOfAddress.files.map((file, index) => {
        return uploadFile(
          file,
          `proof_of_address_${(record.proofOfAddress.originalIds?.length ?? 0) + index + 1}`,
          ["proof_of_address"]
        );
      });

      const proofOfDeathUploads = record.proofOfDeath.files.map((file, index) => {
        return uploadFile(
          file,
          `proof_of_death_${(record.proofOfDeath.originalIds?.length ?? 0) + index + 1}`,
          ["proof_of_death"]
        );
      });

      const willAndProbateUploads = record.willAndProbate.files.map((file, index) => {
        return uploadFile(
          file,
          // ?? and ?. handles situation when user wants to reupload after adding a wrong format file, it might have to be covered for other uploads also
          `will_and_probate_${(record.willAndProbate.originalIds?.length ?? 0) + index + 1}`,
          ["will"]
        );
      });

      const grantOfRepresentationUploads = record.grantOfRepresentation.files.map((file, index) => {
        return uploadFile(
          file,
          `grant_of_representation_${(record.grantOfRepresentation.originalIds?.length ?? 0) + index + 1
          }`,
          ["grant_of_representation"]
        );
      });

      Promise.all([
        Promise.all(proofOfAddressUploads),
        Promise.all(proofOfDeathUploads),
        Promise.all(willAndProbateUploads),
        Promise.all(grantOfRepresentationUploads),
      ]).then(([proofOfAddress, proofOfDeath, willAndProbate, grantOfRepresentation]) => {
        const newIds = {
          proofOfAddress: record.proofOfAddress.currentIds.concat(
            proofOfAddress.map(({ id }) => id)
          ),
          proofOfDeath: record.proofOfDeath.currentIds.concat(proofOfDeath.map(({ id }) => id)),
          willAndProbate: record.willAndProbate.currentIds.concat(
            willAndProbate.map(({ id }) => id)
          ),
          grantOfRepresentation: record.grantOfRepresentation.currentIds.concat(
            grantOfRepresentation.map(({ id }) => id)
          ),
        };

        let futureForm = updatedFormFromPersistedState(form, persistedState, newIds);
        futureForm = prepareUpdate(Section.Documents, futureForm);

        if (!futureForm.documents) {
          throw new Error("Unexpected");
        }

        return updateDocuments({
          caseId,
          signature,
          record: serverRecordFromPersistedState(persistedState, newIds),
        }).then(() => {
          setPersistedState({
            ...persistedState,
            hasChanges: false,
            record: {
              ...persistedState.record,
              proofOfAddress: {
                originalIds: newIds.proofOfAddress,
                currentIds: newIds.proofOfAddress,
                previousDocuments: [],
                files: [],
              },
              proofOfDeath: {
                originalIds: newIds.proofOfDeath,
                currentIds: newIds.proofOfDeath,
                previousDocuments: [],
                files: [],
              },
              willAndProbate: {
                originalIds: newIds.willAndProbate,
                currentIds: newIds.willAndProbate,
                previousDocuments: [],
                files: [],
              },
              grantOfRepresentation: {
                originalIds: newIds.grantOfRepresentation,
                currentIds: newIds.grantOfRepresentation,
                previousDocuments: [],
                files: [],
              },
            },
            errors: {},
            refreshPreviousDocuments: persistedState.refreshPreviousDocuments + 1,
          });
          updateSuccessful(futureForm, Section.Documents, justSave);
        }, updateFailure);
      });
    },
    [
      caseId,
      form,
      hasChanges,
      persistedState,
      signature,
      updateFailure,
      updateSuccessful,
      continueWithoutChanges,
      prepareUpdate,
      setPersistedState,
      record,
      uploadFile,
      askForProofOfAddress,
      askForWillAndProbate,
    ]
  );

  return (
    <DocumentsView
      askForProofOfAddress={askForProofOfAddress}
      askForWillAndProbate={askForWillAndProbate}
      askForGrantOfRepresentation={askForGrantOfRepresentation}
      busy={busy}
      record={record}
      errors={errors}
      hasChanges={hasChanges}
      remoteError={remoteError}
      uploadedFileInfo={uploadedFileInfo}
      setError={setError}
      updateProofOfAddress={updateProofOfAddress}
      updateWillAndProbate={updateWillAndProbate}
      updateGrantOfRepresentation={updateGrantOfRepresentation}
      updateProofOfDeath={updateProofOfDeath}
      onContinue={onContinue}
      onSectionClick={onSectionClick}
      updateTemplate={updateTemplate}
      removeRemoteError={removeRemoteError}
    />
  );
};

export type DocumentsViewProps = {
  askForProofOfAddress?: boolean;
  askForWillAndProbate?: boolean;
  askForGrantOfRepresentation?: boolean;
  busy: boolean;
  record: DocumentsRecord;
  errors: any;
  hasChanges: boolean;
  remoteError?: string;
  uploadedFileInfo: any;
  setError: any;
  updateProofOfAddress: any;
  updateProofOfDeath: any;
  updateWillAndProbate: any;
  updateGrantOfRepresentation: any;
  onContinue: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
};

export const DocumentsView: React.FC<DocumentsViewProps> = ({
  askForProofOfAddress,
  askForWillAndProbate,
  askForGrantOfRepresentation,
  busy,
  record,
  errors,
  hasChanges,
  remoteError,
  uploadedFileInfo,
  setError,
  updateProofOfAddress,
  updateWillAndProbate,
  updateGrantOfRepresentation,
  updateProofOfDeath,
  onContinue,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: "documents",
      onNext: () => onContinue(false),
      onSave: hasChanges ? () => onContinue(true) : undefined,
      onSectionClick,
    });
  }, [busy, onContinue, onSectionClick, updateTemplate, hasChanges]);
  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <Header level={1}>Time to add your documents.</Header>

        <Box>
          <Subheader faded>Top tip</Subheader>

          <Typography variant="body1">
            When uploading photographs or scans of documents, please make sure that the image
            contains <b>all four corners of the page</b> and that{" "}
            <b>all text is clear and legible</b>.
          </Typography>
        </Box>
      </Stack>

      <HR />

      <Stack rowGap={2}>
        <Header color={colors.lightTeal} level={2}>
          Your documents
        </Header>

        <Accordion
          teal
          expanded={!(record.proofOfDeath.files.length === 0)}
          title={
            <RequiredHeader
              showError={!!errors.proofOfDeath || record.proofOfDeath.currentIds.length === 0}
              title="Death certificate"
            />
          }
        >
          <Stack rowGap={1}>
            <UploadField
              uploadedFileInfo={uploadedFileInfo}
              busy={false}
              record={record.proofOfDeath}
              update={updateProofOfDeath}
              error={errors.proofOfDeath}
              setError={(error: any) => setError("proofOfDeath", error)}
            />

            <Typography variant="body1">
              Please upload the death certificate. If a death certificate is not available, please
              upload the <b>Coroner’s Interim Certificate</b>.
            </Typography>
          </Stack>
        </Accordion>

        {askForProofOfAddress && (
          <Accordion
            teal
            expanded={!(record.proofOfAddress.files.length === 0)}
            title={
              <RequiredHeader
                showError={!!errors.proofOfAddress || record.proofOfAddress.currentIds.length === 0}
                title="Proof of your address"
              />
            }
          >
            <Stack rowGap={1}>
              <UploadField
                uploadedFileInfo={uploadedFileInfo}
                busy={false}
                record={record.proofOfAddress}
                update={updateProofOfAddress}
                error={errors.proofOfAddress}
                setError={(error: any) => setError("proofOfAddress", error)}
              />

              <Typography variant="body1">
                Please upload a <b>Council Tax Bill/Demand Letter</b> (issued within the last 12
                months) <b>OR</b> a <b>Utility Bill</b> (issued within the last 3 months.)
              </Typography>

              <div>
                <Typography variant="body1">
                  If you don’t have either of these documents, please upload one of the following:
                </Typography>

                <ul style={{ margin: 0 }}>
                  <li>
                    A Bank, Building Society or Credit Card Statement (issued within the last 3
                    months)
                  </li>
                  <li>A Tenancy Agreement (issued within the last 12 months)</li>
                </ul>
              </div>
            </Stack>
          </Accordion>
        )}

        {askForWillAndProbate && (
          <Accordion
            teal
            expanded={!(record.willAndProbate.files.length === 0)}
            title={
              <RequiredHeader
                showError={!!errors.willAndProbate || record.willAndProbate.currentIds.length === 0}
                title="The Will"
              />
            }
          >
            <Stack rowGap={1}>
              <UploadField
                uploadedFileInfo={uploadedFileInfo}
                busy={false}
                record={record.willAndProbate}
                update={updateWillAndProbate}
                error={errors.willAndProbate}
                setError={(error: any) => setError("willAndProbate", error)}
              />

              <Stack rowGap={2}>
                <Typography variant="body1">
                  If the person who died wrote a will, please upload images, scans or copies of each
                  <b> of the pages below </b>. <b>Please do NOT </b>remove the staples in your
                  original Will. Doing so{" "}
                  <b>
                    {" "}
                    could cause serious issues and raise questions as to the validity of your Will.
                  </b>
                </Typography>

                <ol style={{ margin: 0 }}>
                  <li>the title page</li>
                  <li>the page with the Executor details</li>
                  <li>the signature page</li>
                  {/* <li>
                    any official changes to the will, known as a <b>Codicil</b>
                  </li> */}
                </ol>

                <Typography variant="body1">
                  Please note that financial institutions, such as organisations handling
                  investments, will require a copy of the will.
                </Typography>
              </Stack>
            </Stack>
          </Accordion>
        )}
        {askForGrantOfRepresentation && (
          <Accordion
            expanded={!(record.grantOfRepresentation.files.length === 0)}
            teal
            title={<RequiredHeader showError={false} title="Grant of Representation (optional)" />}
          >
            <Stack rowGap={1}>
              <UploadField
                uploadedFileInfo={uploadedFileInfo}
                busy={false}
                record={record.grantOfRepresentation}
                update={updateGrantOfRepresentation}
                error={errors.grantOfRepresentation}
                setError={(error: any) => setError("grantOfRepresentation", error)}
              />

              <Typography variant="body1">
                If you have received Grant of Representation (<b>Grant of Probate</b> or{" "}
                <b>Letters of Administration</b>), please upload a photograph or scan of this
                document.
              </Typography>
            </Stack>
          </Accordion>
        )}
      </Stack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
    </Stack >
  );
};

const validateRecord = (
  record: DocumentsRecord,
  askForProofOfAddress: boolean,
  askForWillAndProbate: boolean
): Errors | undefined => {
  const errors = {} as Errors;

  if (record.proofOfDeath.files.length === 0 && record.proofOfDeath.currentIds.length === 0) {
    errors.proofOfDeath = "Required";
  }

  if (
    askForProofOfAddress &&
    record.proofOfAddress.files.length === 0 &&
    record.proofOfAddress.currentIds.length === 0
  ) {
    errors.proofOfAddress = "Required";
  }

  if (
    askForWillAndProbate &&
    record.willAndProbate.files.length === 0 &&
    record.willAndProbate.currentIds.length === 0
  ) {
    errors.willAndProbate = "Required";
  }

  return Object.keys(errors).length === 0 ? undefined : errors;
};
type FormErrors = {
  proofOfDeathIds?: string;
  proofOfAddressIds?: string;
  willAndProbateIds?: string;
};

const validate = (
  { documents }: NonExpressFormData,
  isProofOfAddressNeeded: boolean,
  askForWillAndProbate: boolean
): FormErrors | undefined => {
  const errors = {} as FormErrors;

  if (documents?.proofOfDeathIds && documents.proofOfDeathIds.length === 0) {
    errors.proofOfDeathIds = "Required field";
  }

  if (
    isProofOfAddressNeeded &&
    documents?.proofOfAddressIds &&
    documents.proofOfAddressIds.length === 0
  ) {
    errors.proofOfAddressIds = "Required field";
  }

  if (
    askForWillAndProbate &&
    documents?.willAndProbateIds &&
    documents.willAndProbateIds.length === 0
  ) {
    errors.willAndProbateIds = "Required field";
  }

  return Object.keys(errors).length === 0 ? undefined : errors;
};

export const isComplete = (
  form: NonExpressFormData,
  isProofOfAddressNeeded: boolean,
  askForWillAndProbate: boolean
): boolean => {
  return validate(form, isProofOfAddressNeeded, askForWillAndProbate) === undefined;
};
