import * as React from "react";

import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";

import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import { Button } from "../../../components/atoms/Button";
import { Header } from "../../../components/atoms/Header";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { AccountForm } from "../AccountForm";
import { ServiceProvider } from "@customTypes/index";
import { Property } from "../AccountForm/PropertyFields";
import { Person } from "../AccountForm/ResponsibleFields";
import { NonExpressFormData } from "@customTypes/index";
import { createDocumentAndUpload } from "@api/caseApi";

export type AddAccountProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly busy: boolean;
  readonly setBusy: (b: boolean) => void;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly onCancel?: () => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onSectionClick: (section: string) => void;
  readonly updateTemplate: UpdateFormTemplateProc;
  readonly isFirstAccount: boolean;
  readonly remoteError: any;
  readonly form: NonExpressFormData;
};

export const AddAccount: React.FunctionComponent<AddAccountProps> = ({
  caseId,
  signature,
  serviceProviders,
  properties,
  persons,
  isFirstAccount,
  remoteError,
  busy,
  setBusy,
  onCancel,
  onAccountAdded,
  uploadedFileInfo,
  updateTemplate,
  onSectionClick,
  form,
}) => {
  const [nextSection, setNextSection] = React.useState(null as null | string);

  const updateTemplateDelegate = React.useCallback(
    ({ onNext }) => {
      updateTemplate({
        busy,
        currentSection: "accounts",
        nextLabel: "Add account",
        saveLabel: isFirstAccount ? null : "Cancel",
        onNext,
        onSave: onCancel,
        onSectionClick: setNextSection,
      });
    },
    [busy, updateTemplate, isFirstAccount, onCancel]
  );

  const removeRemoteError = () => {
    console.log("TODO");
  };

  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <Header level={1}>Let's add some accounts to your case.</Header>

        <Typography variant="body1">
          You'll also be able to add accounts from your personal dashboard after you submit the
          form.
        </Typography>
      </Stack>
      <AccountForm
        caseId={caseId}
        signature={signature}
        serviceProviders={serviceProviders}
        properties={properties}
        persons={persons}
        setBusy={setBusy}
        onAccountUpdated={onAccountAdded}
        uploadedFileInfo={uploadedFileInfo}
        createDocumentAndUpload={(...args) => createDocumentAndUpload(caseId, signature, ...args)}
        updateTemplate={updateTemplateDelegate}
        form={form}
      />
      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
      <Dialog open={!!nextSection} onClose={() => setNextSection(null)}>
        <DialogTitle id="alert-dialog-title">Confirm </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Moving to another section now will cancel adding this account. Do you want to continue?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="secondary" size="medium" onClick={() => setNextSection(null)}>
            Continue adding account
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={() => nextSection && onSectionClick(nextSection)}
            autoFocus
          >
            Change section
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
