import Call from '@mui/icons-material/Call';
import { Grid, Typography } from "@mui/material";
import { SettldContactInfo } from "@utils/Constants";
import { Link } from "./Link";

export const Help: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} style={{ alignSelf: "center", textAlign: "center" }}>
        <CallIcon />
      </Grid>
      <Grid item xs={11}>
        <ContactUsInfo />
      </Grid>
    </Grid>
  );
};

export const CallIcon: React.FC = () => {
  return (
    <a href={SettldContactInfo.ContactUs} target="_blank" rel="noopener noreferrer">
      <Call  />
    </a>
  );
};

export const ContactUsInfo: React.FC = () => {
  return (
    <>
      <Typography>
        <Link variant="text" target="_blank" to={SettldContactInfo.ContactUs}>Contact Us</Link>
      </Typography>
    </>
  );
};
