import * as React from "react";

import {
  AccountNextStep,
  ServiceProviderType,

} from "@src/types";
import { FieldRow, FieldsTable } from "@molecules/FieldsTable";
import { Person, Responsible } from "./Responsible";
import { readableAccountNextSteps } from "@src/utils/accounts";

type MobileAccountProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly account: {
    serviceProviderType?: ServiceProviderType;
    phoneNumber?: string;
    nextSteps?: AccountNextStep;
    responsibleId?: string;
  };
};

export const MobileAccount: React.FC<MobileAccountProps> = ({
  persons,
  account: { serviceProviderType, phoneNumber, nextSteps, responsibleId },
}) => {
  return (
    <FieldsTable>
      <FieldRow label="Mobile number" value={phoneNumber} />

      <FieldRow label="Next Step" value={readableAccountNextSteps(nextSteps, serviceProviderType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />
    </FieldsTable>
  );
};
