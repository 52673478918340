import { Message } from "./pages/Dashboard/Chat/message";
import { Property, PropertyStatus } from "./pages/NotifierForm/AccountForm/PropertyFields";
import { Account, AccountStates, AccountStatuses, EnergyType } from "./types/Account";
import { Case, CaseStates } from "./types/Case";
import { Document } from "./types/Document";
import {  Person } from "@customTypes/Person";
import {
  AccountDetails,
  ServiceProviderType,
  ContactMethods,
  PersonDetails,
  Relationship,
  Titles,
} from "@customTypes/index";
import { ServiceProvider } from "./types/ServiceProvider";

export const makeAccount = ({
  state = AccountStates.Open,
  status = AccountStatuses.Processing,
  serviceProvider = makeServiceProvider(),
  responsible = makePerson(),
} = {}): Account => {
  return {
    insertedAt: new Date().toISOString(),
    state,
    status,
    serviceProvider,
    propertyRemainsOccupied: true,
    responsible,
    requiresNotifierAttention: false,
    id: "account",
    energyType: EnergyType.DualFuel,
  };
};

export const makeAccountDetails = ({
  id = "account",
  companyId,
  companyName,
  serviceProviderType,
  serviceProvider,
  accountNumber = "12345678",
  sortCode = "123456",

  property,
  propertyId,
}: any = {}): AccountDetails => {
  if (!serviceProvider) {
    serviceProvider = makeServiceProvider();
  }

  if (!companyId) {
    companyId = serviceProvider.id;
  }

  if (!serviceProviderType) {
    serviceProviderType = serviceProvider.serviceProviderType;
  }

  if (!companyName) {
    companyName = serviceProvider.companyName;
  }

  if (!property) {
    property = makeProperty();
  }

  if (!propertyId) {
    propertyId = property.id;
  }

  return {
    id,
    companyId,
    serviceProviderType,
    companyName,
    accountNumber,
    sortCode,
    propertyId,
    energyType: EnergyType.DualFuel,
  };
};

export const makeDocument = ({
  id = "document",
  filename = "Document",
  tags = [],
} = {}): Document => {
  return {
    id,
    filename,
    downloadUrl: "https://example.com/document",
    uploadedAt: new Date(),
    tags,
  };
};

export const makeNotifier = (): Person => {
  return {
    id: "notifier",
    title: Titles.Mrs,
    dateOfBirth: new Date().toISOString(),
    contactMethod: ContactMethods.Email,
    contactNumber: "07123456789",
    firstName: "Jane",
    lastName: "Doe",
    email: "jane@example.com",
    address: "1 Example Street",
    city: "Example City",
    postcode: "EX1 1EX",
  };
};

export const makeMessage = (): Message => {
  return {
    id: "message",
    content: "This is a message",
    isReadByNotifier: false,
    isReadByServiceProvider: false,
    isReadBySettld: false,
    ownMessage: true,
    settldMessage: false,
    senderName: "Jane Doe",
    companyName: "Example Company",
    insertedAt: new Date().toISOString(),
    deletedAt: null,
    documents: [],
  };
};

export const makePerson = ({
  id = "notifier",
  title,
  firstName,
  lastName,
}: Partial<PersonDetails> = {}): PersonDetails => {
  return {
    id,
    title,
    contactNumber: "07123456789",
    firstName,
    lastName,
    email: "jane@example.com",
    address: "1 Example Street",
    city: "Example City",
   postcode: "EX1 1EX",
  };
};

export const makeProperty = ({
  id = "property",
  address = "1 Example Street",
  city = "Example City",
  postcode = "EX1 1EX",
  status = PropertyStatus.Vacant,
} = {}): Property => {
  return {
    id,
    address,
    city,
    postcode,
    status,
    // lastOccupiedDate?
    // handOverDate?
    // ownershipStatus?
    // occupierId?
  };
};

export const makeServiceProvider = ({
  id = "sp1",
  companyName = "Example Company",
  serviceProviderType = ServiceProviderType.Banking,
  slug,
}: Partial<ServiceProvider> = {}): ServiceProvider => {
  return {
    id,
    companyName,
    serviceProviderType,
    slug,
  };
};

export const makeCase = ({
  notifier = makeNotifier(),
  accounts = [makeAccount()],
} = {}): Case => {
  return {
    alternateId: 42,
    state: CaseStates.Open,
    deceasedTitle: Titles.Mr,
    deceasedFirstName: "John",
    deceasedLastName: "Doe",
    deceasedEmail: "john@example.com",
    deceasedPhone: "071234567890",
    notifierRelationshipToDeceased: Relationship.Spouse,
    notifierRelationshipToDeceasedOther: "",
    notifier,
    deceasedAt: new Date().toISOString(),
    accounts,
    accountCount: 3,
    processingAccountCount: 1,
    settledAccountCount: 1,
    blockedAccountCount: 1,
    formSubmittedAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    id: "id",
    attachments: [],
  };
};
