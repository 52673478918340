import * as React from "react";

import { UtilitiesAccountFields } from "./UtilitiesAccountFields";
import { AccountFieldProps } from "./AccountForm";


export type EnergyAccountFieldsProps = AccountFieldProps;

export const EnergyAccountFields: React.FC<EnergyAccountFieldsProps> = ({
  properties,
  persons,
  serviceProvider,
  account,
  setBusy,
  uploadedFileInfo,
  createDocumentAndUpload,
  onAccountAdded ,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails,
}) => {
  return (
    <UtilitiesAccountFields
      properties={properties}
      persons={persons}
      serviceProvider={serviceProvider}
      setBusy={setBusy}
      onAccountAdded={onAccountAdded}
      account={account}
      uploadedFileInfo={uploadedFileInfo}
      createDocumentAndUpload={createDocumentAndUpload}
      version={"energy"}
      updateTemplate={updateTemplate}
      saveAccount={saveAccount}
      saveRecord={saveRecord}
      savedRecord={savedRecord}
      askForDeceasedDetails={askForDeceasedDetails}
    />
  );
};
