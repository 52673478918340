import * as React from "react";

import { Box, Grid, Paper, Stack } from "@mui/material";

import { ServiceProviderType } from "@customTypes/index";
import { SectorIcon } from "../atoms/SectorIcon";
import { colors } from "../../styles/constants";

export type SectorGroup = {
  label: string;
  sectors: ReadonlyArray<ServiceProviderType>;
  newSPSector: ServiceProviderType;
};

type TitleInputProps = {
  value: SectorGroup | undefined;
  onValueChange: (value: SectorGroup) => void;
};

const sectorGroups: ReadonlyArray<SectorGroup> = [
  {
    label: "Banking",
    sectors: [ServiceProviderType.Banking],
    newSPSector: ServiceProviderType.Banking,
  },
  {
    label: "Building Society",
    sectors: [ServiceProviderType.BuildingSociety],
    newSPSector: ServiceProviderType.BuildingSociety,
  },
  {
    label: "Insurance",
    sectors: [ServiceProviderType.Insurance],
    newSPSector: ServiceProviderType.Insurance,
  },
  {
    label: "Pensions",
    sectors: [ServiceProviderType.Pension],
    newSPSector: ServiceProviderType.Pension,
  },
  {
    label: "Investments",
    sectors: [ServiceProviderType.Investments],
    newSPSector: ServiceProviderType.Investments,
  },
  {
    label: "Energy",
    sectors: [ServiceProviderType.Energy],
    newSPSector: ServiceProviderType.Energy,
  },
  {
    label: "Water",
    sectors: [ServiceProviderType.Water],
    newSPSector: ServiceProviderType.Water
  },
  {
    label: "Credit cards/Loans",
    sectors: [
      ServiceProviderType.CreditCards,
      ServiceProviderType.Mortgage,
      ServiceProviderType.Loans,
    ],
    newSPSector: ServiceProviderType.Other,
  },
  {
    label: "Telecommunications",
    sectors: [
      ServiceProviderType.Broadband,
      ServiceProviderType.Mobile,
      ServiceProviderType.Satellite,
      ServiceProviderType.Telecoms,
      ServiceProviderType.TV,
    ],
    newSPSector: ServiceProviderType.Other,
  },
  {
    label: "Subscriptions",
    sectors: [
      ServiceProviderType.Charities,
      ServiceProviderType.Ecommerce,
      ServiceProviderType.LoyaltyCards,
      ServiceProviderType.Magazines,
      ServiceProviderType.SaaS,
      ServiceProviderType.Streaming,
      ServiceProviderType.Subscriptions,
    ],
    newSPSector: ServiceProviderType.Other,
  },
  {
    label: "Social Media",
    sectors: [ServiceProviderType.Email, ServiceProviderType.SocialMedia],
    newSPSector: ServiceProviderType.SocialMedia,
  },
  {
    label: "Other",
    sectors: [ServiceProviderType.Betting, ServiceProviderType.Other],
    newSPSector: ServiceProviderType.Other,
  },
];

export const findGroupFromServiceProviderType = (
  serviceProviderType?: ServiceProviderType
): SectorGroup | null => {
  if (!serviceProviderType)  return null;

  return sectorGroups.find((group) => group.sectors.includes(serviceProviderType)) || null;
};

export const ServiceProviderTypeInput: React.FC<TitleInputProps> = (props) => {
  const { onValueChange, value } = props;

  return (
    <Grid container spacing={2} alignItems="stretch">
      {sectorGroups.map((group, index) => {
        return (
          <SectorItem
            key={index}
            focus={value === group}
            label={group.label}
            sector={group.sectors[0]}
            onValueChange={() => onValueChange(group)}
          />
        );
      })}
    </Grid>
  );
};

type SectorItemProps = {
  focus?: boolean;
  label: string;
  sector: ServiceProviderType;
  onValueChange: () => void;
};

const SectorItem: React.FC<SectorItemProps> = ({ focus, label, sector, onValueChange }) => {
  return (
    <Grid item xs={6} sm={3} sx={{ minHeight: "100px" }}>
      <Paper
        variant="outlined"
        sx={{
          height: "100%",
          padding: 1,
          backgroundColor: focus ? colors.accentTeal : "transparent",
          cursor: "pointer",
        }}
        onClick={onValueChange}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Box style={{ color: focus ? "white" : colors.fadedText }}>
            <SectorIcon fontSize="large" color="inherit" sector={sector} />
          </Box>

          <Box sx={{ textAlign: "center", color: focus ? "white" : "inherit" }}>{label}</Box>
        </Stack>
      </Paper>
    </Grid>
  );
};
