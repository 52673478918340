import * as React from "react";

import { NotifierRoles, Relationship } from "@customTypes/index";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { clearExpressSectionDataByKey, getExpressSectionSourceDataByKey, putExpressSectionDataByKey } from "./express-data";
import { NotifierDetailsView, NotifierRecord, NotifierRecordErrors } from "./NotifierDetailsView";
import { ServiceProvider } from "@src/types/ServiceProvider";
import { ExpressUiInfoContext } from "@src/store/ExpressUiInfo";
import { validEmailAddress } from "@utils/Functions";
import { uism } from "@src/utils/Intestacy";

const currentSection = "notifier";

export type NotifierDetailsState = {
  record: NotifierRecord;
  hasChanges: boolean;
  errors: NotifierRecordErrors;
};

export type CaseState = {
  record: { willAvailable?: boolean };
  hasChanges: boolean;
  errors: { willAvailable?: boolean };
};

export function personDetailsToServerRecord(record: NotifierRecord): any {
  const title = record.title?.toLowerCase();
  return {
    title,
    firstName: record.firstName,
    lastName: record.lastName,
    dateOfBirth: record.dateOfBirth,
    address: record.address,
    city: record.city,
    postcode: record.postcode,
    email: record.email,
    contactNumber: record.contactNumber,
    relationshipToDeceased:
      record.role === "solicitor" ? "other" : record.relationshipToDeceased?.toLowerCase(),
    relationshipToDeceasedOther:
      record.role === "solicitor" ? "Solicitor" : record.relationshipToDeceasedOther,
    role: record.role,
    isVulnerable: record.isVulnerable,
    nok: record.nok,
  };
}

type NotifierDetailsProps = {
  serviceProvider: ServiceProvider;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  next: () => void;
};

export const NotifierDetails: React.FC<NotifierDetailsProps> = ({
  serviceProvider,
  onSectionClick,
  updateTemplate,
  next,
}) => {
  const { state, dispatch } = React.useContext(ExpressUiInfoContext);
  const [{ record, hasChanges, errors }, setState] = React.useState<NotifierDetailsState>({
    record: {},
    hasChanges: false,
    errors: {},
  });
  const [caseData, setCaseData] = React.useState<CaseState>({
    record: {},
    hasChanges: false,
    errors: {},
  });

  const titleHidden = serviceProvider.customForm === "starling-bank";

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          [name]: value,
        },
        errors: {
          ...errors,
          [name]: undefined,
        },
      });
    },
    [record, errors, setState]
  );

  const onDateOfBirthChange = React.useCallback(
    (value) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          dateOfBirth: value,
        },
        errors: {
          ...errors,
          dateOfBirth: undefined,
        },
      });
    },
    [record, errors, setState]
  );

  const onRoleChange = React.useCallback(
    (value) => {
      let state = {
        hasChanges: true,
        record: {
          ...record,
          role: value,
          dateOfBirth: value === "solicitor" ? undefined : record.dateOfBirth,
          isVulnerable: value === "solicitor" ? false : record.isVulnerable,
          relationshipToDeceased: value === "solicitor" ? undefined : record.relationshipToDeceased,
          relationshipToDeceasedOther:
            value === "solicitor" ? undefined : record.relationshipToDeceasedOther,
        },
        errors: {
          ...errors,
          role: undefined,
          dateOfBirth: undefined,
          isVulnerable: undefined,
        },
      };
      let [newNotifierDetailsState, newCaseData] = clearUiOnRoleChange(state, caseData);

      dispatch({
        type: "SET_STATE",
        payload: uism(newNotifierDetailsState.record, !!caseData.record.willAvailable)
      });
      setCaseData(newCaseData)
      setState(newNotifierDetailsState);
    },
    [record, errors, setState, caseData, dispatch]
  );

  const onIsVulnerableChange = React.useCallback(
    (value) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          isVulnerable: value,
        },
        errors,
      });
    },
    [record, errors, setState]
  );

  const onNokChange = React.useCallback(
    (value) => {
      
      dispatch({
        type: "SET_STATE",
        payload: uism({ ...record, nok: value }, !!caseData.record.willAvailable)
      });

      setState({
        hasChanges: true,
        record: {
          ...record,
          nok: value,
        },
        errors,
      });
    },
    [record, errors, setState, caseData, dispatch]
  );

  const onWillChange = React.useCallback(
    (value) => {
      dispatch({
        type: "SET_STATE",
        payload: uism(record, value)
      });
      setCaseData((s) => ({ ...s, record: { willAvailable: value }, hasChanges: true }));
    },
    [caseData, setCaseData, dispatch, record]
  );

  React.useEffect(() => {
    const data = getExpressSectionSourceDataByKey(currentSection);
    const localCaseData = getExpressSectionSourceDataByKey("case");

    if (!data) {
      setState(({ record }) => ({ record, hasChanges: false, errors: {} }));
      return;
    }
    if (!localCaseData) {
      setCaseData(({ record }) => ({ record, hasChanges: false, errors: {} }));
      return;
    }

    setCaseData({ record: localCaseData, hasChanges: false, errors: {} });
    setState({ record: data, hasChanges: false, errors: {} });
  }, [setState, setCaseData]);

  const onNext = React.useCallback(() => {
    if (state.collectExecutorDetails && !state.collectNokDetails) {
      clearExpressSectionDataByKey("nok")
    }
    if (!state.collectExecutorDetails && state.collectNokDetails) {
      clearExpressSectionDataByKey("executor")
    }
    const errors = errorsOf(record, titleHidden, caseData, serviceProvider);
    const caseErrors = errorsOfCase(record, caseData, serviceProvider);

    if (caseErrors) {
      setCaseData((s) => ({ ...s, errors: caseErrors }));
      putExpressSectionDataByKey("case", { source: caseData.record });
      return;
    }

    if (errors) {
      setState({ hasChanges, record, errors });
      putExpressSectionDataByKey(currentSection, { source: record });
      return;
    }

    if (!hasChanges && !caseData.hasChanges) {
      next();
      return;
    }

    putExpressSectionDataByKey(currentSection, {
      source: record,
      target: personDetailsToServerRecord(record),
    });
    putExpressSectionDataByKey("case", { source: caseData.record });
    putExpressSectionDataByKey("case", { source: caseData.record, target: caseData.record });
    next();
  }, [titleHidden, record, hasChanges, next, caseData, serviceProvider]);

  const onSectionClickExtended = React.useCallback(
    (section) => {
      if (hasChanges) {
        const errors = errorsOf(record, titleHidden, caseData, serviceProvider);
        putExpressSectionDataByKey(currentSection, {
          source: record,
          target: errors ? undefined : personDetailsToServerRecord(record),
        });
      }

      onSectionClick(section);
    },
    [titleHidden, record, hasChanges, onSectionClick]
  );

  return (
    <NotifierDetailsView
      serviceProvider={serviceProvider}
      titleHidden={titleHidden}
      errors={errors}
      record={record}
      onRoleChange={onRoleChange}
      onNext={onNext}
      onDateOfBirthChange={onDateOfBirthChange}
      onFieldChange={onFieldChange}
      onSectionClick={onSectionClickExtended}
      updateTemplate={updateTemplate}
      onIsVulnerableChange={onIsVulnerableChange}
      showIDVerificationDialog={false}
      hideIDVerificationDialog={() => { }}
      onNokChange={onNokChange}
      caseData={caseData}
      setCaseData={onWillChange}
    />
  );
};

const getRequiredFields = (isNok: boolean, isExecutor: boolean): Array<keyof NotifierRecord> => {
  if (isExecutor || isNok) {
    return [
      "firstName",
      "lastName",
      "email",
      "role",
    ]
  }

  return [
    "firstName",
    "lastName",
    "contactNumber",
    "email",
    "role",
  ];
}

const errorsOfCase = (record: any, caseData: any, serviceProvider: any): any | undefined => {
  let errors: any = {};
  if (record.role === "delegated_notifier" && serviceProvider.intestacyFlow) {
    if (caseData.record.willAvailable === undefined) {
      errors = { ...errors, willAvailable: "required" };
    }
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};

const errorsOf = (
  record: NotifierRecord,
  titleOptional: boolean,
  caseData: any,
  serviceProvider: any
): NotifierRecordErrors | undefined => {
  let errors: NotifierRecordErrors = {};

  let isExecutor = [NotifierRoles.SoleExecutor, NotifierRoles.Executor].includes(record.role as NotifierRoles);
  let requiredFields = getRequiredFields(!!record.nok, isExecutor);

  if (!validEmailAddress(record?.email || "")) {
    errors.email = "Not a valid email address";
  }

  if (!titleOptional) {
    if (!record?.title) {
      errors = { ...errors, title: "required" };
    }
  }

  requiredFields.forEach((key) => {
    if (!record || !record[key]) {
      errors = { ...errors, [key]: "required" };
    }
  });

  if (serviceProvider.intestacyFlow) {
    const needsNok =
      record.role === "administrator" ||
      (record.role === "delegated_notifier" && !caseData.record.willAvailable);

    if (needsNok && record["nok"] === undefined) {
      errors = { ...errors, nok: "required" };
    }
  }

  if (!record || (!record.dateOfBirth && record.role !== "solicitor")) {
    errors = { ...errors, dateOfBirth: "required" };
  }

  if (record.role !== "solicitor" && !record.relationshipToDeceased) {
    errors = { ...errors, relationshipToDeceased: "required" };
  }

  if (
    record.role !== "solicitor" &&
    record.relationshipToDeceased === Relationship.Other &&
    !record.relationshipToDeceasedOther
  ) {
    errors = { ...errors, relationshipToDeceasedOther: "required" };
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

function clearUiOnRoleChange(data: NotifierDetailsState, caseData: CaseState): [NotifierDetailsState, CaseState] {
  switch (data.record.role) {
    case NotifierRoles.SoleExecutor:
    case NotifierRoles.Executor:
    case NotifierRoles.Solicitor:
      return [{ ...data, record: { ...data.record, nok: undefined } }, { ...caseData, record: { willAvailable: true } }];
    case NotifierRoles.Administrator:
      return [{ ...data, record: { ...data.record, nok: undefined } }, { ...caseData, record: { willAvailable: false } }];
    case NotifierRoles.DelegatedNotifier:
      return [{ ...data, record: { ...data.record, nok: undefined } }, { ...caseData, record: { willAvailable: undefined } }];
  }
  return [data, caseData];
}

