import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { usePageViewEventTracker } from "@utils/analytics";
import { Account } from "../../types/Account";
import { Attachment } from "../../types/Attachment";
import { Case } from "../../types/Case";
import urlPaths from "../../urlPaths";
import { Header } from "../../components/atoms/Header";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { Accounts } from "./Accounts";

import { getCase } from "@api/caseApi";
import { SettldContactInfo } from "@utils/Constants";
import { CallIcon } from "@src/components/atoms/HelpInfo";
import { AdditionalServicesAccordion } from "./AdditionalServices";
import { CaseSummary } from "./CaseSummary";
import { KeyDocuments } from "./KeyDocuments";
import { DashboardInfoContext } from "@src/store/DashboardProvider";

type StatusPageProps = {
  readonly caseId: string;
  readonly signature: string | null;
};

export const StatusPage: React.FC<StatusPageProps> = ({ caseId, signature }) => {
  usePageViewEventTracker("Case status");
  const { state: { refreshCounter }, dispatch } = React.useContext(DashboardInfoContext);

  const navigate = useNavigate();

  const [state, setState] = React.useState({ loading: false, error: false, caseRecord: null } as {
    loading: boolean;
    error: boolean;
    caseRecord: (Case & { accounts: Array<Account>; attachments?: Attachment[] }) | null;
  });

  const { caseRecord } = state;


  React.useEffect(() => {
    getCase({ caseId, signature })
      .then((res) => {
        if (!res.data?.case) return Promise.reject(new Error("Missing data."));

        let { case: caseRecord, persons } = res.data;
        dispatch({ type: "SET_ALL", payload: { ...res.data, caseId: caseId, signature: signature } });

        caseRecord.accounts.sort((a: Account, b: Account) => {
          const aCompanyName = a.serviceProvider?.companyName || '';
          const bCompanyName = b.serviceProvider?.companyName || '';

          const s = aCompanyName.localeCompare(bCompanyName);

          return s === 0 ? (a.insertedAt || '').localeCompare(b.insertedAt || '') : s;

        });

        if (noAccessToDashboardYet(caseRecord)) {
          navigate(urlPaths.root(), { replace: true });
          return;
        }

        setState({ loading: false, error: false, caseRecord });
      })
      .catch((err) => {
        setState((s) => ({ ...s }));
        console.warn({ err });
      });
  }, [caseId, signature, navigate, refreshCounter]);

  if (!caseRecord) return null;

  return (
    <StatusPageView
      caseId={caseId}
      signature={signature}
      caseRecord={caseRecord}
    />
  );
};

export type StatusPageViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly caseRecord: Case & { accounts: Array<Account>; attachments?: Attachment[] };
};

export const StatusPageView: React.FC<StatusPageViewProps> = ({
  caseId,
  signature,
  caseRecord,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  if (matches) {
    // mobile
    return (
      <SimpleTemplate busy={false} headerType="signout">
        <Stack rowGap={2}>
          <Header level={1}>Accounts dashboard</Header>

          <IntrodutoryText expressCaseNotSubmitted={expressCaseNotSubmitted(caseRecord)} />

          <AdditionalServicesAccordion
            caseId={caseId}
            signature={signature}
            caseRecord={caseRecord}
          />

          <Accounts
            caseId={caseId}
            signature={signature}
            accounts={caseRecord.accounts}
            expressCaseNotSubmitted={expressCaseNotSubmitted(caseRecord)}
            setBusy={() => { }}
          />

          <div>
            <h2>Documents</h2>
            <KeyDocuments caseRecord={caseRecord} />
          </div>
        </Stack>
      </SimpleTemplate>
    );
  }

  // desktop
  return (
    <SimpleTemplate busy={false} headerType="signout">
      <Stack rowGap={2}>
        <Header level={1}>Accounts dashboard</Header>

        <Grid container spacing={4}>
          <Grid item xs={7}>
            <IntrodutoryText expressCaseNotSubmitted={expressCaseNotSubmitted(caseRecord)} />
          </Grid>

          <Grid item xs={5}>
            <CaseSummary caseRecord={caseRecord} />

            <KeyDocuments caseRecord={caseRecord} />
          </Grid>
        </Grid>

        <AdditionalServicesAccordion
          caseId={caseId}
          signature={signature}
          caseRecord={caseRecord}
        />

        <Accounts
          caseId={caseId}
          signature={signature}
          accounts={caseRecord.accounts}
          expressCaseNotSubmitted={expressCaseNotSubmitted(caseRecord)}
          setBusy={() => { }}
        />
      </Stack>
    </SimpleTemplate>
  );
};


const IntrodutoryText = ({ expressCaseNotSubmitted }: { expressCaseNotSubmitted: boolean }) => {
  return (
    <div>
      <p>Thank you for submitting your case.</p>

      <p>
        <b style={{ textDecoration: "underline" }}>Useful tips:</b>
      </p>
      <ol>
        <li>
          To add extra accounts, press{" "}
          <b>{expressCaseNotSubmitted ? "+ Add more accounts" : "+ Add account"}</b>.
        </li>
        <li>
          For each company, the <b>Account updates</b> section holds important documents and
          information. Use the messaging box there, to ask us questions or send information.
        </li>
        <li>
          Keep an eye on the <b>Requires your attention</b> section, as we may need something extra
          from you to complete the account request.
        </li>
        <li>
          If you need any <b>other services</b> from our Partners, please select them below.
        </li>
        <li>
          To speak to one of our team send us Email at {SettldContactInfo.Email} or Call us at  {SettldContactInfo.Telephone} (9am to 5pm, Monday to Friday)   <CallIcon />
        </li>
      </ol>
    </div>
  );
};

const noAccessToDashboardYet = (caseRecord: any) => {
  return caseRecord.source !== "express_form" && !caseRecord.formSubmittedAt;
};

const expressCaseNotSubmitted = (caseRecord: any) => {
  return caseRecord.source === "express_form" && !caseRecord.formSubmittedAt;
};


