import React from "react";
import { Stack, Typography } from "@mui/material";

import {
  AccountDetails,
  AccountManagement,
  ServiceProviderType,
  ServiceProvider,
} from "@customTypes/index";
import {
  ServiceProviderTypeInput,
  findGroupFromServiceProviderType,
  SectorGroup,
} from "../../../components/molecules/ServiceProviderTypeInput";
import { FormField } from "../../../components/molecules/FormField";
import { CompanyNameInput } from "./CompanyNameInput";
import { newSPInfoBox } from "@src/components/atoms/infoBox";

// Define the Record type for the component's state
export type Record = {
  readonly serviceProviderId?: string;
  readonly group?: SectorGroup;
  readonly serviceProviderType?: ServiceProviderType;
  readonly addingServiceProvider?: boolean;
  readonly customProviderName?: string;
  readonly customForm?: string;
  readonly accountManagement?: AccountManagement;
  readonly showAMModal?: boolean;
};

// Define a generic Updater type for updating state
type Updater<T> = ((value: T) => void) & ((updaterFn: (prevValue: T) => T) => void);

/**
 * Function to create a Record from an existing account or service provider.
 * This helps in pre-populating the form fields when editing an existing account.
 */
export const recordFromAccount = (
  account: AccountDetails | undefined,
  serviceProvider?: ServiceProvider
): Record => {
  if (serviceProvider) {
    // If a service provider is provided, construct the record from it
    return {
      serviceProviderId: serviceProvider.id,
      group: findGroupFromServiceProviderType(serviceProvider.serviceProviderType) || undefined,
      serviceProviderType: serviceProvider.serviceProviderType,
      addingServiceProvider: false,
      customForm: serviceProvider.customForm,
      accountManagement: serviceProvider.accountManagement,
    };
  }

  if (account) {
    // If an account is provided, construct the record from it
    return {
      serviceProviderId: account.companyId,
      group: findGroupFromServiceProviderType(account.serviceProviderType) || undefined,
      serviceProviderType: account.serviceProviderType,
      addingServiceProvider: false,
      accountManagement: account.accountManagement,
    };
  }

  return {};
};

export type ServiceProviderFieldsProps = {
  serviceProviders: ReadonlyArray<ServiceProvider>;
  record: Record;
  errors: any;
  update: Updater<Record>;
};

/**
 * ServiceProviderFields component allows the user to select a sector and a service provider.
 */
export const ServiceProviderFields: React.FC<ServiceProviderFieldsProps> = ({
  serviceProviders,
  record,
  errors,
  update,
}) => {
  /**
   * Callback to set a new custom service provider name when the user inputs a name not in the list.
   */
  const setNewServiceProviderName = React.useCallback(
    (name: string) => {
      update(({ group }) => ({
        addingServiceProvider: true,
        group,
        serviceProviderType: group?.newSPSector,
        customProviderName: name,
      }));
    },
    [update]
  );

  /**
   * Callback when a service provider is selected from the list.
   */
  const setServiceProvider = React.useCallback(
    (
      serviceProviderId: string,
      serviceProviderType: ServiceProviderType,
      customForm: string,
      accountManagement: AccountManagement
    ) => {
      update(({ group }) => ({
        addingServiceProvider: false,
        group,
        serviceProviderType,
        serviceProviderId,
        customForm,
        accountManagement,
        showAMModal: shouldShowAMInfoBox(accountManagement),
      }));
    },
    [update]
  );

  /**
   * Callback to clear the selected service provider, resetting to the initial state.
   */
  const clear = React.useCallback(() => {
    update(({ group }) => ({
      addingServiceProvider: false,
      group,
      serviceProviderType: group?.newSPSector,
    }));
  }, [update]);

  /**
   * Callback when the sector group changes (e.g., the user selects a different sector icon).
   */
  const onNewServiceProviderTypeChange = React.useCallback(
    (group: SectorGroup) => {
      update({
        addingServiceProvider: false,
        group,
        serviceProviderType: group.newSPSector,
      });
    },
    [update]
  );

  return (
    <>
      {/* Form field prompting the user to select the sector */}
      <FormField>
        <Typography variant="body1">What sector does this account apply to?</Typography>
      </FormField>

      {/* Input component for selecting the service provider type (sector) */}
      <FormField>
        <ServiceProviderTypeInput
          value={record.group}
          onValueChange={onNewServiceProviderTypeChange}
        />
      </FormField>

      {/* If a sector group is selected, display the company name input */}
      {record.group && (
        <FormField label={newSPInfoBox()}>
          <CompanyNameInput
            serviceProviders={serviceProviders}
            sectors={record.group.sectors}
            serviceProviderId={record.serviceProviderId}
            customProviderName={record.customProviderName}
            setNewServiceProviderName={setNewServiceProviderName}
            setServiceProvider={setServiceProvider}
            clear={clear}
          />
        </FormField>
      )}
    </>
  );
};

/**
 * Helper function to determine if the Account Management Info Modal should be shown.
 * It checks if the account management status is among NotManaged,Unknown,NotifyOnly.
 */
function shouldShowAMInfoBox(accountManagement: AccountManagement | null | undefined): boolean {
  return (
    accountManagement === AccountManagement.NotManaged ||
    accountManagement === AccountManagement.Unknown ||
    accountManagement === AccountManagement.NotifyOnly ||
    accountManagement === null ||
    accountManagement === undefined
  );
}
