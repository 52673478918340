import * as React from "react";

import { Relationship, PersonDetails, NotifierRoles } from "../../types/FormData";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { getExpressSectionSourceDataByKey, putExpressSectionDataByKey } from "./express-data";
import { NokDetailsView, PersonDetailsErrors } from "./NokDetailsView";
import { validEmailAddress } from "@utils/Functions";

const currentSection = "nok";

export type NokDetailsState = {
  record: PersonDetails;
  hasChanges: boolean;
  errors: PersonDetailsErrors;
};


export function personDetailsToServerRecord(record: PersonDetails): any {
  const title = record.title?.toLowerCase();
  return {
    title,
    firstName: record.firstName,
    lastName: record.lastName,
    dateOfBirth: record.dateOfBirth,
    address: record.address,
    city: record.city,
    postcode: record.postcode,
    email: record.email,
    contactNumber: record.contactNumber,
    relationshipToDeceased: record.relationshipToDeceased?.toLowerCase(),
    relationshipToDeceasedOther: record.relationshipToDeceasedOther,
    roles: ["nok"],
    isVulnerable: record.isVulnerable,
    nok: true
  };
}

type NokDetailsProps = {
  serviceProvider: { customForm?: string };
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  next: () => void;
};

export const NokDetails: React.FC<NokDetailsProps> = ({
  serviceProvider,
  onSectionClick,
  updateTemplate,
  next,
}) => {
  const [{ record, hasChanges, errors }, setState] = React.useState<NokDetailsState>({
    record: { role:  NotifierRoles.DelegatedNotifier, },
    hasChanges: false,
    errors: {},
  });

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          [name]: value,
        },
        errors: {
          ...errors,
          [name]: undefined,
        },
      });
    },
    [record, errors, setState]
  );

  const onDateOfBirthChange = React.useCallback(
    (value) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          dateOfBirth: value,
        },
        errors: {
          ...errors,
          dateOfBirth: undefined,
        },
      });
    },
    [record, errors, setState]
  );

  const onIsVulnerableChange = React.useCallback(
    (value) => {
      setState({
        hasChanges: true,
        record: {
          ...record,
          isVulnerable: value,
        },
        errors,
      });
    },
    [record, errors, setState]
  );

  React.useEffect(() => {
    const data = getExpressSectionSourceDataByKey(currentSection);

    if (!data) {
      setState(({ record }) => ({ record, hasChanges: false, errors: {} }));
      return;
    }

    setState({ record: data, hasChanges: false, errors: {} });
  }, [setState]);

  const onNext = React.useCallback(() => {
    const errors = errorsOf(record);
    if (errors) {
      setState({
        hasChanges,
        record,
        errors,
      });
      putExpressSectionDataByKey(currentSection, { source: record });
      return;
    }

    if (!hasChanges) {
      next();
      return;
    }

    putExpressSectionDataByKey(currentSection, {
      source: record,
      target: personDetailsToServerRecord(record),
    });
    next();
  }, [ record, hasChanges, next]);

  const onSectionClickExtended = React.useCallback(
    (section) => {
      if (hasChanges) {
        const errors = errorsOf(record);
        putExpressSectionDataByKey(currentSection, {
          source: record,
          target: errors ? undefined : personDetailsToServerRecord(record),
        });
      }

      onSectionClick(section);
    },
    [ record, hasChanges, onSectionClick]
  );

  return (
    <NokDetailsView
      serviceProvider={serviceProvider}
      errors={errors}
      record={record}
      onNext={onNext}
      onDateOfBirthChange={onDateOfBirthChange}
      onFieldChange={onFieldChange}
      onSectionClick={onSectionClickExtended}
      updateTemplate={updateTemplate}
      onIsVulnerableChange={onIsVulnerableChange}
      showIDVerificationDialog={false}
      hideIDVerificationDialog={() => { }}
    />
  );
};

const requiredFields: ReadonlyArray<keyof PersonDetails> = [
  "title",
  "firstName",
  "lastName",
  "email",
];

const errorsOf = (
  record: PersonDetails,
): PersonDetailsErrors | undefined => {
  let errors: PersonDetailsErrors = {};

  if (!validEmailAddress(record?.email || "")) {
    errors.email = "Not a valid email address";
  }

  requiredFields.forEach((key) => {
    if (!record || !record[key]) {
      errors = { ...errors, [key]: "required" };
    }
  });

  if (
    record.role !== "solicitor" &&
    record.relationshipToDeceased === Relationship.Other &&
    !record.relationshipToDeceasedOther
  ) {
    errors = { ...errors, relationshipToDeceasedOther: "required" };
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};
