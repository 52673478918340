import * as React from "react";

import {
  AddOutlined,
  CreditCardOutlined,
  CurrencyPoundOutlined,
  GroupsOutlined,
  HomeOutlined,
  LightbulbOutlined,
  PhoneOutlined,
  PolicyOutlined,
  SavingsOutlined,
  ShoppingCartOutlined,
  ShowChartOutlined,
  ShowerOutlined,
  SignalCellularAltOutlined,
  SubscriptionsOutlined,
} from "@mui/icons-material";
import { ServiceProviderType } from "@customTypes/index";

export type SectorIconProps = {
  readonly color?:
  | "inherit"
  | "disabled"
  | "action"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
  readonly fontSize?: "small" | "medium" | "large";
  readonly sector: ServiceProviderType;
};

export const SectorIcon: React.FC<SectorIconProps> = ({ color, fontSize, sector }) => {
  switch (sector) {
    case ServiceProviderType.Banking:
      return <CurrencyPoundOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Broadband:
      return <PhoneOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.BuildingSociety:
      return <HomeOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Charities:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.CreditCards:
      return <CreditCardOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Ecommerce:
      return <ShoppingCartOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Email:
      return <GroupsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Energy:
      return <LightbulbOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Insurance:
      return <PolicyOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Investments:
      return <ShowChartOutlined color={color} fontSize={fontSize} />;
    // case ServiceProviderType.MortgageOrLoans:
    //   return <HomeOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Pension:
      return <SavingsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.SocialMedia:
      return <GroupsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Subscriptions:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Satellite:
      return <SignalCellularAltOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Telecoms:
      return <PhoneOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.TV:
      return <SignalCellularAltOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Magazines:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Water:
      return <ShowerOutlined color={color} fontSize={fontSize} />;
    case ServiceProviderType.Other:
    default:
      return <AddOutlined color={color} fontSize={fontSize} />;
  }
};
