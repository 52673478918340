import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { usePageViewEventTracker } from "@utils/analytics";
import { login } from "@utils/session";
import { validEmailAddress } from "../../utils/Functions";
import { BusyOverlay } from "../../components/atoms/BusyOverlay";
import { Button } from "../../components/atoms/Button";
import { EmailAddressInput } from "../../components/atoms/EmailAddressInput";
import { Header, Subheader } from "../../components/atoms/Header";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { SelectInput } from "../../components/atoms/SelectInput";
import { TextInput } from "../../components/atoms/TextInput";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { Link } from "../../components/atoms/Link";
import { State, Record, SignupPageProps, SignupPageViewProps } from "../../utils/Types";
import { ValidationError, errorsFromServer } from "../../utils/Errors";
import { signup } from "@api/signupApi";
import { SignupReferralOptions } from "../../utils/Constants";
import { Box, Stack } from "@mui/material";
import { Help } from "@src/components/atoms/HelpInfo";

export const SignupPage: React.FC<SignupPageProps> = ({ nextPageURL }) => {
  const { website } = useParams() as { website: string };

  const knownWebsite = {
    nwl: "Northumbrian Water",
    eswater: "Essex & Suffolk Water",
  }[website];

  usePageViewEventTracker("Signup");

  const [state, setState] = React.useState({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      emailAddress: "",
      password: "",
      passwordConfirmation: "",
      referral: knownWebsite ? "website" : undefined,
      referralOther: knownWebsite,
    },
    validated: false,
  } as State);

  const { busy, errorMessage, errors, record, validated } = state;

  const navigate = useNavigate();

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [name]: undefined,
        },
        record: { ...s.record, [name]: value },
        validated: false,
      }));
    },
    [setState]
  );

  const onReferralValueChange = React.useCallback((value) => {
    setState((s) => ({
      ...s,
      record: { ...s.record, referral: value, referralOther: "" },
      validated: false,
      errors: { ...s.errors, referral: "", referralOther: "" },
    }));
  }, []);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, validated: true, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none" };

      // // @ts-ignore
      // window.grecaptcha.ready(() => {
      //   // @ts-ignore
      //   window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
      //     submitData({ ...record, captcha: token });
      //   });
      // });

      signup(data).then(
        (data) => {
          setState((s) => ({ ...s, busy: false }));
          const { session } = data;
          login(session.token);
          navigate(nextPageURL, { replace: true });
        },
        (error) => {
          if (error instanceof ValidationError) {
            setState((s) => ({
              ...s,
              busy: false,
              errors: errorsFromServer(error),
              validated: true,
            }));
          } else {
            setState((s) => ({ ...s, busy: false, errorMessage: error.message }));
          }
        }
      );
    },
    [record, navigate, nextPageURL, setState]
  );

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  return (
    <SignupPageView
      validated={validated}
      record={record}
      errors={errors}
      website={website}
      errorMessage={errorMessage}
      busy={busy}
      onSubmit={onSubmit}
      onChange={onChange}
      onReferralValueChange={onReferralValueChange}
      removeErrorMessage={removeErrorMessage}
    />
  );
};

export const SignupPageView: React.FC<SignupPageViewProps> = ({
  validated,
  record,
  errors,
  website,
  errorMessage,
  busy,
  onSubmit,
  onChange,
  onReferralValueChange,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <SideImageTemplate
        headerType="signin"
        errorMessage={errorMessage}
        removeErrorMessage={removeErrorMessage}
        image={
          <img
            src="/images/stock/signup.png"
            width="100%"
            alt="Family"
            style={{ paddingTop: "100px" }}
          />
        }
        sx={{ background: `url(/images/background/signup.svg) no-repeat center #d1dee0` }}
      >
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <Stack rowGap={4}>
            <Box>
              <Header>Simple and secure bereavement admin support </Header>
              <Subheader>
                Close or transfer their accounts, apply for probate, sell the property - and much
                more. Select as many services as you need, in one form.
              </Subheader>
            </Box>

            <FormStack>
              <FormField halfWidth label="First name">
                <TextInput
                  name="firstName"
                  value={record.firstName || ""}
                  error={!validated ? null : errors.firstName}
                  onChange={onChange}
                />
              </FormField>

              <FormField halfWidth label="Last name">
                <TextInput
                  name="lastName"
                  value={record.lastName || ""}
                  error={!validated ? null : errors.lastName}
                  onChange={onChange}
                />
              </FormField>

              <FormField halfWidth label="Email address">
                <EmailAddressInput
                  name="emailAddress"
                  value={record.emailAddress || ""}
                  error={!validated ? null : errors.emailAddress}
                  onChange={onChange}
                />
              </FormField>

              <FormField halfWidth label="Phone number">
                <TextInput
                  name="phoneNumber"
                  value={record.phoneNumber || ""}
                  error={!validated ? null : errors.phoneNumber}
                  onChange={onChange}
                />
              </FormField>

              <FormField halfWidth label="Password">
                <PasswordInput
                  name="password"
                  value={record.password || ""}
                  error={!validated ? null : errors.password}
                  onChange={onChange}
                />
              </FormField>

              <FormField halfWidth label="Confirm password">
                <PasswordInput
                  name="passwordConfirmation"
                  value={record.passwordConfirmation || ""}
                  error={!validated ? null : errors.passwordConfirmation}
                  onChange={onChange}
                />
              </FormField>

              <FormField slightlyMoreThanHalfWidth label="How did you hear about us?">
                <SelectInput
                  name="referral"
                  onValueChange={onReferralValueChange}
                  value={record.referral || ""}
                  options={SignupReferralOptions}
                  error={!validated ? null : errors.referral}
                />
              </FormField>

              {(record.referral === "website" ||
                record.referral === "social_media" ||
                record.referral === "other") && (
                <FormField
                  label={
                    record.referral === "website"
                      ? "Which website?"
                      : record.referral === "social_media"
                      ? "Which social media?"
                      : ""
                  }
                >
                  <TextInput
                    error={!validated ? null : errors.referral}
                    name="referralOther"
                    value={record.referralOther || ""}
                    onChange={onChange}
                  />
                </FormField>
              )}
            </FormStack>

            <FormStack>
              <FormField>
                <div>
                  By clicking the ‘Create Account’ button, you are creating a Settld account and you
                  agree to Settld's{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/terms-and-conditions.html"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/privacy-policy.html"
                  >
                    Privacy Policy
                  </Link>
                  .
                </div>
              </FormField>
            </FormStack>

            <FormStack>
              <FormField slightlyMoreThanHalfWidth>
                <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                  Create account
                </Button>
              </FormField>
            </FormStack>
          </Stack>
        </form>

        {/* <div
          className="g-signin2"
          data-onsuccess="(googleUser) => {
            const profile = googleUser.getBasicProfile();
            console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
            console.log('Name: ' + profile.getName());
            console.log('Image URL: ' + profile.getImageUrl());
            console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
          }"
        >
        </div> */}
        <br />
        <Help />
      </SideImageTemplate>
    </BusyOverlay>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    firstName: !record.firstName ? "required" : undefined,
    lastName: !record.lastName ? "required" : undefined,
    emailAddress: !record.emailAddress
      ? "required"
      : !validEmailAddress(record.emailAddress)
      ? "invalid"
      : undefined,
    phoneNumber: !record.phoneNumber ? "required" : undefined,
    password: !record.password
      ? "required"
      : [/[0-9]/, /[a-zA-Z]/, /[^0-9a-zA-Z]/, /......../].every((re) => re.test(record.password))
      ? undefined
      : "Please ensure your password is at least 8 characters in length, and contains a combination of letters, numbers and symbols",
    passwordConfirmation: !record.passwordConfirmation ? "required" : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};
