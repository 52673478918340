import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { AccountDetails } from "@customTypes/index";
import { Header, Subheader } from "../../../components/atoms/Header";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { AddedAccountCard } from "./AddedAccountCard";
import { AddCard } from "./AddCard";
import { HR } from "../../../components/atoms/HR";
import { colors } from "@styles/constants";

export type AccountsListProps = {
  accounts: ReadonlyArray<AccountDetails>;
  busy: any;
  onRemoveAccount: any;
  onContinue: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onAddAccount: () => void;
  onEditAccount: (account: AccountDetails) => void;
};

export const AccountsList: React.FC<AccountsListProps> = ({
  accounts,
  busy,
  onRemoveAccount,
  onContinue,
  onSectionClick,
  updateTemplate,
  onAddAccount,
  onEditAccount,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: "accounts",
      onNext: () => onContinue(false),
      onSave: () => { },
      onSectionClick: onSectionClick,
    });
  }, [busy, accounts, onContinue, onSectionClick, updateTemplate]);

  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <Header level={1}>Let’s add some accounts to your case.</Header>

        <Typography variant="body1">
          You'll also be able to add accounts from your personal dashboard after you submit the
          form.
        </Typography>
      </Stack>

      <HR />

      <Stack rowGap={2}>
        <Header color={colors.lightTeal} level={2}>
          Your added accounts(s)
        </Header>

        <AddCard label="Add account" onClick={onAddAccount} />

        {accounts.map((account, index) => (
          <AddedAccountCard
            key={`${account.companyName}-${account.serviceProviderType}-${index}`}
            account={account}
            index={index}
            onRemove={onRemoveAccount}
            onEditAccount={onEditAccount}
          />
        ))}
      </Stack>
    </Stack>
  );
};
