import * as React from "react";

import { ServiceProviderType } from "@customTypes/index";
import { formatDate } from "@utils/Functions";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { readableOwnershipStatus } from "../../Dashboard/RequestDetails/PropertyDetails";
import { readableLandlordType } from "../../NotifierForm/AccountForm/PropertyFields/OwnershipStatusField";
import { formatPropertyStatus, OwnershipStatus, PropertyStatus } from "../../../types/property";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps, readableMeterType } from "@src/utils/accounts";

export const EnergyAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: ServiceProviderType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow label="Account number" value={account.accountNumber || <NotAvailable />} />

      <FieldRow
        label="Property address"
        value={
          <>
            {account.property.details.address}
            <br />
            {account.property.details.city}
            <br />
            {account.property.details.postcode}
          </>
        }
      />

      <FieldRow label="Property status" value={formatPropertyStatus(account.property.status)} />

      {account.property.status === PropertyStatus.ExistingOccupier ? (
        <FieldRow
          label="Existing occupant"
          value={
            account.property.occupier.id === "notifier" ? (
              "Myself"
            ) : (
              <PersonDetails person={account.property.occupier.details} />
            )
          }
        />
      ) : (
        <>
          <FieldRow
            label="Occupied date"
            value={
              account.property.lastOccupiedDate
                ? formatDate(account.property.lastOccupiedDate)
                : "Date of death"
            }
          />
          <FieldRow
            label="Ownership status"
            value={readableOwnershipStatus(account.property.ownershipStatus)}
          />

          {account.property.ownershipStatus === OwnershipStatus.Rented && (
            <>
              <FieldRow
                label="Landlord"
                value={
                  <>
                    {readableLandlordType(account.property.landlordType)}
                    <br />
                    {account.property.landlordName || ""}
                    <br />
                    {account.property.landlordAddress || ""}
                    <br />
                    {account.property.landlordPhoneNumber || ""}
                  </>
                }
              />
            </>
          )}

          <FieldRow
            label="Responsible"
            value={
              account.responsible.id === "notifier" ? (
                "Myself"
              ) : (
                <PersonDetails person={account.responsible.details} />
              )
            }
          />
        </>
      )}

      {!!account.responsibleRejectsArrears && (
        <FieldRow
          label="Arrears"
          value={
            <span>
              Existing occupant(s) is <b>not</b> willing to accept responsibility for arrears
            </span>
          }
        />
      )}

      <FieldRow label="Meter" value={readableMeterType(account.meterType)} />

      {account.lastMeterReadingDate && (
        <FieldRow label="Meter reading date" value={formatDate(account.lastMeterReadingDate)} />
      )}

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />
    </>
  );
};
