import * as React from "react";

import { AccountNextStep, ServiceProviderType } from "@src/types";
import { RadioGroupInput } from "@src/components/atoms";

type AccountNextStepInputProps = {
  serviceProviderType: ServiceProviderType;
  value?: string;
  error?: string;
  onValueChange: (value: string) => void;
};

export const AccountNextStepInput: React.FC<AccountNextStepInputProps> = ({
  serviceProviderType,
  value,
  error,
  onValueChange,
}) => {
  return (
    <RadioGroupInput
      onValueChange={onValueChange}
      value={value}
      fields={fieldsFor(serviceProviderType)}
      error={error}
    />
  );
};

const fieldsFor = (serviceProviderType?: ServiceProviderType) => {
  if (serviceProviderType === ServiceProviderType.Insurance || serviceProviderType === ServiceProviderType.Pension) {
    return insuranceFields;
  }

  if (serviceProviderType === ServiceProviderType.Broadband) {
    return telecomsFields;
  }

  if (serviceProviderType === ServiceProviderType.Mobile) {
    return mobileFields;
  }

  if (serviceProviderType === ServiceProviderType.Telecoms) {
    return telecomsFields;
  }

  if (serviceProviderType === ServiceProviderType.TV) {
    return telecomsFields;
  }

  return defaultFields;
};

const defaultFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  {
    value: AccountNextStep.UpdateAccountHolder,
    label: "Update primary account holder",
  },
];

const insuranceFields = [
  { value: AccountNextStep.Cancel, label: "Close the policy" },
  {
    value: AccountNextStep.UpdateAccountHolder,
    label: "Update policy holder",
  },
];

const telecomsFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  { value: AccountNextStep.UpdateAccountHolder, label: "Transfer the account" },
];

const mobileFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  { value: AccountNextStep.UpdateAccountHolder, label: "Transfer the account" },
  { value: AccountNextStep.MoveToPayAsYouGo, label: "Move to Pay as you go" },
  { value: AccountNextStep.MoveToAnotherService, label: "Move to another mobile network" },
];
