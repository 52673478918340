import { Typography, Box } from "@mui/material";
import { RadioGroupInput } from "../atoms/RadioGroupInput";
import { FormField } from "./FormField";
import { ServiceProvider } from "@src/types/ServiceProvider";

interface WillExistsQuestionProps {
  serviceProvider: ServiceProvider;
  caseData: any;
  askForWill: boolean | undefined;
  setCaseData: (a: any) => void;
}
export const WillExistsQuestion: React.FC<WillExistsQuestionProps> = ({
  askForWill,
  caseData,
  serviceProvider,
  setCaseData,
}) => {
  if (!askForWill) return null;
  return (
    <FormField
      label={
        <>
          <Typography variant="body1">
            <strong>Is there a Will?</strong> If so, you’ll need to add this to the document
            section.
          </Typography>
        </>
      }
    >
      <br />
      <RadioGroupInput
        error={caseData.errors?.willAvailable}
        value={ getWill(caseData) }
        fields={[
          { value: "false", label: <> There is no Will.</> },
          {
            value: "true",
            label: (
              <>
                {serviceProvider.intestacyFlow && <>Yes, there is a Will.</>}
                {!serviceProvider.intestacyFlow && <> Will exists</>}
              </>
            ),
          },
        ]}
        onValueChange={(v) => setCaseData(v === "true")}
      />
    </FormField>
  );
};

function getWill(caseData: any){
  if(caseData.record.willAvailable===true) return "true";
  if(caseData.record.willAvailable===false) return "false";
  return undefined;
}