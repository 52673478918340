export enum Colors {
  TEAL = "#41EAD4",
  RED = "#F50744",
  LIGHT_PURPLE = "#9D8DF1",
  DARK_BLUE = "#020887",
}

export const SignupReferralOptions = [
  { value: "word_of_mouth", label: "Word of mouth" },
  { value: "website", label: "Another Website" },
  { value: "online_search", label: "Online search" },
  { value: "news", label: "In the news" },
  { value: "social_media", label: "Social media" },
  { value: "care_home", label: "Care home" },
  { value: "celebrant_or_officiant", label: "Celebrant/Officiant" },
  { value: "charity", label: "Charity" },
  { value: "funeral_director", label: "Funeral director" },
  { value: "health_care_worker", label: "Health care worker" },
  { value: "hospice", label: "Hospice" },
  { value: "local_council", label: "Local council" },
  { value: "registrar", label: "Registrar" },
  { value: "other", label: "Other" },
];

export enum SettldContactInfo {
  Telephone = "0333 111 1111",
  ContactUs = "https://www.settld.care/contact/",
  Email ="support@settld.care"
}